import * as React from 'react';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';

import { IUser } from '../../api/user/IUser';
import { ProfileApi } from '../../api/profile/ProfileApi';
import { useAuth } from '../../auth/useAuth';
import { IProfile } from '../../api/profile/IProfile';

interface IProps {
  /** Initial form data. */
  data: IUser;
  /** Called whenever form changes. */
  onChange: (data: IUser, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
  // Is password a required field?
  requirePassword?: boolean;
}

const UserForm = (props: IProps) => {
  const auth = useAuth();
  const [profiles, setProfiles] = React.useState<IProfile[]>([]);
  const [data, setData] = React.useState<IUser>(props.data);

  const loadProfiles = () => {
    ProfileApi.list('name', 'asc', 0, 100, null, auth.access_token)
    .then(res => setProfiles(res.data));
  }

  React.useEffect(loadProfiles, []);

  return (
    <Form
      data={data} dirty={props.dirty} onChange={props.onChange} onValidate={props.onValidate}>
      <Flex.Columns count={2}>
        <Form.Field 
          required={{message: "User name is required."}}
          minLength={{length: 3, message: "User name must be at least 3 characters."}}
          maxLength={{length: 255, message: "User name may not be longer than 255 characters."}}
          width={1} label="User name"
          name="name" 
          value={data.name}
          control={(<Input type="text" placeholder="Name" fluid/>)}
          hint={(<>Please enter a user name, e.g. <code>John Doe</code></>)}/>
        <Form.Field 
          required={{message: "Email is required."}}
          email={{message: "Email must be a valid email address."}}
          width={1} label="Email"
          name="email" 
          value={data.email}
          control={(<Input type="text" placeholder="Email" fluid/>)}
          hint={(<>Please enter the user's email address, e.g. <code>john.doe@gmail.com</code></>)}/>
        <Form.Field
          required={{message: "User profile is required."}}
          width={1} label="Profile"
          name="profile" 
          value={data.profile}
          control={(<Dropdown fluid data={profiles} placeholder="Profile" label={(item:IProfile) => item.name}>
            <Dropdown.Column weight={3}>{(item:IProfile) => item.name}</Dropdown.Column>
          </Dropdown>)}
          hint="Please select a user access profile."/>
        <Form.Field 
          required={props.requirePassword ? {message: "Password is required."} : null}
          minLength={{length: 8, message: "Password must be at least 8 characters."}}
          maxLength={{length: 30, message:"Password may not be longer than 30 characters."}}
          noPattern={{pattern: '^(.{0,7}|.{31,}|[^0-9]*|[^A-Za-z]*|[a-zA-Z0-9]*)$', message: "Password must contain at least one digit and one special character."}}            
          width={1} label="Password"
          name="password" 
          value={data.password}
          control={(<Input type="text" placeholder="Password" fluid/>)}
          hint={(props.requirePassword ? <>Please choose the user's password.</> : <>Choose new password, or leave empty to retain original password.</>)}/>          
      </Flex.Columns>
    </Form>
  )
}

export { UserForm }
