import * as React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { Signin } from './routes/signin/Signin';
import { PublicRoute } from './routes/PublicRoute';
import { ProtectedRoute } from './routes/ProtectedRoute';
import { NoMatch } from './routes/nomatch/NoMatch';
import { Users } from './routes/users/Users';
import { AddUser } from './routes/users/AddUser';
import { ViewUser } from './routes/users/ViewUser';
import { EditUser } from './routes/users/EditUser';
import { Logs } from './routes/log/Logs';
import { Profiles } from './routes/profiles/Profiles';
import { ViewProfile } from './routes/profiles/ViewProfile';
import { AddProfile } from './routes/profiles/AddProfile';
import { EditProfile } from './routes/profiles/EditProfile';
import { AddAccident } from './routes/accidents/AddAccident';
import { ViewAccident } from './routes/accidents/ViewAccident';
import { EditAccident } from './routes/accidents/EditAccident';
import { Account } from './routes/account/Account';
import { Forgot } from './routes/forgot/Forgot';
import { Reset } from './routes/reset/Reset';

// Router documentation:
// Intro: https://www.robinwieruch.de/react-router/
// Authentication: https://www.robinwieruch.de/react-router-authentication/

const Router = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="signin" element={<PublicRoute><Signin/></PublicRoute>}/>
        <Route path="password/request" element={<PublicRoute><Forgot/></PublicRoute>}/>
        <Route path="reset/:token" element={<PublicRoute><Reset/></PublicRoute>}/>
        <Route path="" element={<ProtectedRoute/>}/>
        <Route path="home" element={<ProtectedRoute/>}/>
        <Route element={<ProtectedRoute/>}>
          
          <Route path="account" element={<Account/>}/>

          <Route path="users" element={<Users/>}/>
          <Route path="user/add" element={<AddUser/>}/>
          <Route path="user/:uuid" element={<ViewUser/>}/>
          <Route path="user/edit/:uuid" element={<EditUser/>}/>

          <Route path="profiles" element={<Profiles/>}/>
          <Route path="profile/:uuid" element={<ViewProfile/>}/>
          <Route path="profile/add" element={<AddProfile/>}/>
          <Route path="profile/edit/:uuid" element={<EditProfile/>}/>

          <Route path="accident/add" element={<AddAccident/>}/>
          <Route path="accident/:uuid" element={<ViewAccident/>}/>
          <Route path="accident/edit/:uuid" element={<EditAccident/>}/>

          <Route path="logs" element={<Logs/>}/>

        </Route>
        <Route path="*" element={<PublicRoute><NoMatch/></PublicRoute>}/>
      </Routes>
    </HashRouter>
  );
}

export { Router }
