import * as React from 'react';
import styled from 'styled-components';

import { Flex, Gravatar } from '@independent-software/typeui/controls';
import { Datum } from '@independent-software/typeui/formatters/Datum';

interface IProps {
  label: React.ReactNode;
  user: { name: string; email: string; };
  time: string;
}

const StampRow = (props: IProps) => 
  <div style={{padding: '8px 16px'}}>
    <Flex.Quick>
      <Gravatar email={props.user ? props.user.email : null}/>
      <Block style={{color: props.user ? 'inherit' : '#aaa'}}>
        <Username>{props.user ? props.user.name : "Unknown user"}</Username>
        <Time>
          {props.label}&nbsp;
          {props.time && <React.Fragment>
              <Datum.Distance value={props.time}/>
          </React.Fragment>}
        </Time>
      </Block>
    </Flex.Quick>          
  </div>

const Username = styled.div`
  font-weight: 500;
  white-space: nowrap;
`

const Block = styled.div`
  padding-left: 10px;
`

const Time = styled.div`
  font-size: 80%;
  white-space: nowrap;
`

export { StampRow }
