import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@independent-software/typeui/styles/Theme';

import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Button } from '@independent-software/typeui/controls/Button'
import { Icon } from '@independent-software/typeui/controls/Icon'

import { AuthApi } from '../../api/auth/AuthApi';
import { ForgotForm } from './ForgotForm';

// Adds a timeout to make sure that request button animation actually gets seen:
const BUTTON_TIMEOUT = 250; // ms

const Forgot = () => {
  const navigate = useNavigate();

  const [credentials, setCredentials] = React.useState<{ email: string }>({ email: sessionStorage.getItem('email') });
  const [loading, setLoading] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  const handleChange = (data: { email: string }, forceupdate: boolean) => {
    setCredentials(data);
  }  

  const handleValidate = (valid: boolean) => {
    setValid(valid);
  }  

  const handleSubmit = () => {
    if(loading) return;
    setLoading(true);
    setTimeout(() => {
      AuthApi.request(credentials.email)
      .then(res => {
        setLoading(false);
        ToastService.toast("Request successful. Email sent.");
        navigate("/");
      })
      .catch(err => {
        setLoading(false);
        ToastService.toast("Request failed.");
      });
    }, BUTTON_TIMEOUT);
    // Adds a 250ms timeout to make sure that request button
    // animation actually gets seen.    
  }    

  return (
    <div>
      <Heading>Request password reset</Heading>
      <ForgotForm disabled={loading} data={credentials} onChange={handleChange} onValidate={handleValidate} onSubmit={handleSubmit}/>
      <div style={{display: 'flex', 'justifyContent': 'space-between'}}>
        <Button primary disabled={!valid} onClick={handleSubmit}>
          {loading  && <Icon loading name="circle-notch"/>}
          {!loading && "Request reset"}
          {loading  && "Requesting..."}
        </Button>
      </div>
    </div>
  )
}

const Heading = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #616A7C;
  margin-bottom: 8px;
`  

export { Forgot }
