import * as React from 'react';

import { Form } from '@independent-software/typeui/controls/Form';
import { Password } from '@independent-software/typeui/modules/Password';

interface IProps {
  /** Initial form data. */
  data: {password: string};
  /** Called whenever form changes. */
  onChange: (data: { password: string }, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** Are form controls disabled? */
  disabled?: boolean;
  dirty?: boolean;
  onSubmit: () => void;
}

const ResetForm = (props: IProps) => {
  const [data, setData] = React.useState<{password: string}>(props.data);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if(e.charCode == 13) props.onSubmit();
  }
  
  return (
    <div onKeyPress={handleKeyPress}>
      <Form data={data} dirty={props.dirty} onChange={props.onChange} onValidate={props.onValidate}>
        <Form.Field
          required={{message: "New password is required."}}
          minLength={{length: 8, message: "New password must be at least 8 characters."}}
          maxLength={{length: 30, message:"New password may not be longer than 30 characters."}}
          noPattern={{pattern: '^(.{0,7}|.{31,}|[^0-9]*|[^A-Za-z]*|[a-zA-Z0-9]*)$', message: "New password must contain at least one digit and one special character."}}            
          width={1} label="New password"
          name="password" 
          value={data.password}
          control={<Password autocomplete="off" fluid placeholder="New password"/>}
          hint="Please enter your new password."
        />
      </Form>
    </div>
  );
}

export { ResetForm }
