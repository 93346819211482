import * as React from 'react';
import styled from 'styled-components';

import { UI } from '../../../components/UI/UI';
import { Lookup } from '../Lookup';
import { FactorButton } from '../controls/FactorButton';
import { IFactor } from '../../../api/accident/IFactor';

interface IProps {
  factors: IFactor[];
  onToggle: (idx: number) => void;
}

const FormFactors = (props: IProps) => {

  return (
    <>
      {Lookup.getCategories.map((category, cat_index) => 
        <UI.Block title={category} key={cat_index}>
          <FactorList>
            {Lookup.getFactors.map((factor, factor_index) => {
              if(factor.category == cat_index) {
                return <FactorButton selected={!!props.factors.find(f => f.value == factor_index)} key={factor_index} onClick={() => props.onToggle(factor_index)}>{factor.name}</FactorButton>
              } else {
                return null;
              }
            })}
          </FactorList>
        </UI.Block>
      )}
    </>
  )
}

const FactorList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export { FormFactors }
