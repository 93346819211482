import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** Input string */
  value: string;
  /** String to search for. Only the first occurrence is highlighted. */
  q: string;
}

/**
 * Given an input string and a search query, highlights the first occurrence
 * (if any) of the search query and returns colored string (as a ReactNode).
 */ 
const Highlight = (props: IProps) => {
  // Is there a search string? If not, do not highlight.
  if(!props.q) return props.value;
  if(!props.value) return props.value;

  // Does q occur inside input string? If not, do not highlight.
  const pos = props.value.toLowerCase().indexOf(props.q.toLowerCase());
  if(pos == -1) return props.value;

  const left = props.value.substring(0, pos);
  const match = props.value.substring(pos, pos + props.q.length);
  const right = props.value.substring(pos + props.q.length);
  return <>{left}<Match>{match}</Match>{right}</>;
}

const Match = styled.span`
  color: steelblue;
  font-weight: 500;  
  text-decoration: underline;
`

export { Highlight }
