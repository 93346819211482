import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Message } from '@independent-software/typeui/controls';

import { UserApi } from '../../api/user/UserApi';
import { useAuth } from '../../auth/useAuth';
import { UserForm } from './UserForm';
import { IUser } from '../../api/user/IUser';
import { UI } from '../../components/UI/UI';

type TStep = 'ready' | 'loading' | 'loadError' | 'saving' | 'saveError' | 'error';

const EditUser = () => {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const currentUser = React.useRef(null);
  const [user, setUser] = React.useState<IUser>(null);
  const [step, setStep] = React.useState<TStep>("loading");
  const [dirty, setDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState(null)

  const loadUser = () => {
    setError(null);
    setStep('loading');
    UserApi.get(params.uuid, auth.access_token)
    .then(res => {
      currentUser.current = res;
      setUser(res);
      setStep('ready');
    })
    .catch((err) => {
      setStep('loadError');
      setError(err);
    });
  }

  React.useEffect(() => {
    loadUser();
  }, []);

  const handleChange = (newUser: IUser, forceupdate: boolean) => {
    currentUser.current = Object.assign(currentUser.current, newUser);
  }

  const handleValidate = (valid: boolean) => {
    setIsValid(valid);
  }  

  const handleSubmit = () => {
    setUser(currentUser.current);
    setDirty(true);
    if(!isValid) return;
    setError(null);
    setStep('saving');

    UserApi.update(currentUser.current, auth.access_token)
    .then(() => {
      ToastService.toast("User updated successfully.");
      navigate(`/user/${user.uuid}`);
    })
    .catch(error => {
      setStep('saveError');
      setError(error);
    });
  }

  return (
    <UI.PaddedScreen>
      <UI.Header>Edit User</UI.Header>
      {(step == 'loading' || step == 'saving') && <Loader></Loader>}

      {/* Form */}
      {step != 'loading' && step != 'loadError' && step != 'error' && <>
        <UI.Content>
          <UserForm dirty={dirty} data={user} onChange={handleChange} onValidate={handleValidate}/>
        </UI.Content>
        <UI.Footer>
          <Button secondary onClick={() => navigate(`/user/${params.uuid}`)}>Cancel</Button>
          <Button primary disabled={!isValid} onClick={handleSubmit}><Icon name="save"/> Save user</Button>
        </UI.Footer>
      </>}

      {/* Fatal error */}
      {step == 'error' && <>
        <UI.Content>
          <Message type="error">The requested data could not be found.</Message>
        </UI.Content>
        <UI.Footer>
          <Button secondary onClick={() => navigate(`/user/${params.uuid}`)}>Cancel</Button>
        </UI.Footer>
      </>}

      {/* Load error, retryable */}
      <Dialog.Xhr open={step == 'loadError'} error={error} onClose={() => setStep('error')} onRetry={loadUser}/>
      
      {/* Save error, retryable */}
      <Dialog.Xhr open={step == 'saveError'} error={error} onClose={() => setStep('ready')} onRetry={handleSubmit}/>

    </UI.PaddedScreen>
  );
}

export { EditUser }
