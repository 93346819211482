import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, DataTable, Icon } from '@independent-software/typeui/controls';
import { TDir } from '@independent-software/typeui/controls/Types';

import { useFilter } from '../../contexts/useFilter';
import { IAccidentProxy } from '../../api/accident/IAccidentProxy';
import { Datum } from '@independent-software/typeui/formatters/Datum';
import styled from '@independent-software/typeui/styles/Theme';
import { Export } from '../../controls/Export';

const AccidentList= () => {
  const filter = useFilter();
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<string>("uuid");
  const [dir, setDir] = React.useState<TDir>('asc');

  const handleOrder = (newOrder: string, newDir: TDir) => {
    setOrder(newOrder);
    setDir(newDir);
    if(order === newOrder) setDir(dir === 'asc' ? 'desc' : 'asc');
  }

  const handleSelectAccident = (accident: IAccidentProxy) => {
    navigate(`/accident/${accident.uuid}`);
  }

  //
  // Compare numbers of strings, and return -1 if a < b, 1 if a > b, or 0 if
  // a and b are equal.
  // 
  const compareAny = (a: any, b: any): number => {
    if(a == undefined || a == null) return -1;
    if(b == undefined || b == null) return 1;
    if(typeof a === 'number') return a < b ? -1 : (a > b ? 1 : 0);
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }

  const orderedAccidents = filter.accidents.sort((a, b) => {
    let comparison = compareAny((a as any)[order], (b as any)[order]);
    if(dir == 'desc') comparison = 1 - comparison;
    return comparison;
  });

  const handleClick = (e: React.MouseEvent, accident: IAccidentProxy) => {
    e.stopPropagation();
    if(filter.tableState == 'full') {
      filter.setTableState('open');
    }
    filter.map.flyTo({ center: [accident.longitude, accident.latitude], zoom: 11 });
  }

  return (
    <>
      <DataTable loading={filter.loading} data={orderedAccidents} order={order} dir={dir} onOrder={handleOrder} onClick={handleSelectAccident}>
        <DataTable.Column weight={4} label="Date/time" order="datetime" dir="asc">
          {(item:IAccidentProxy) => <Datum.Custom value={item.datetime} format='dd MMM yyy HH:mm:ss'/>}
        </DataTable.Column>
        <DataTable.Column weight={2} label="Town" order="general_town" dir="asc">
          {(item:IAccidentProxy) => item.general_town}
        </DataTable.Column>        
        <DataTable.Column weight={2} label="Vehicles" order="vehicles_count" dir="desc">
          {(item:IAccidentProxy) => item.vehicles_count}
        </DataTable.Column>        
        <DataTable.Column weight={2} label="Casualties" order="casualties_count" dir="desc">
          {(item:IAccidentProxy) => <Box>
            {item.casualties_count}
            <Icon name="search" title="Zoom to accident" onClick={(e?: React.MouseEvent) => handleClick(e, item)}/>
          </Box>}
        </DataTable.Column>        
      </DataTable>  
      <Footer>
        <Export onExport={(format: string) => filter.handleExport(format)}/>
      </Footer>
    </>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Footer = styled.div`
  padding: 12px;
  border-top: solid 1px #aaa;
  background: #eee;
`

export { AccidentList }
