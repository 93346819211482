import * as React from 'react';
import { Flex, Table } from '@independent-software/typeui/controls';

import { IAccident } from '../../../api/accident/IAccident';
import { UI } from '../../../components/UI/UI';
import { Answer } from '../controls/Answer';
import { Lookup } from '../Lookup';

interface IProps {
  accident: IAccident;
}

const TableSituation = (props: IProps) =>
  <Flex.Columns count={2}>
    
    <div>
      <UI.Block title="Road">
        <Table striped transparent>
          <tbody>
            <Answer label="Road Type" value={props.accident.situation_road_type} src={Lookup.getRoadTypes}/>
            {props.accident.situation_road_type == 2 && <Answer label="Road Lane" value={props.accident.situation_road_lane} src={Lookup.getRoadLanes}/>}
            <Answer label="Speed Limit (Permanent)" value={props.accident.situation_speed_limit}/>
          </tbody>
        </Table>      
      </UI.Block>
      <UI.Block title="Junction">
        <Table striped transparent>
          <tbody>
            <Answer label="Junction Type" value={props.accident.situation_junction_type} src={Lookup.getJunctionTypes}/>
            {props.accident.situation_junction_type > 0 && <Answer label="Junction Control" value={props.accident.situation_junction_control} src={Lookup.getJunctionControl}/>}
          </tbody>
        </Table>      
      </UI.Block>      
      <UI.Block title="Crossing">
        <Table striped transparent>
          <tbody>
            <Answer label="Pedestrian Crossing - Human Control" value={props.accident.situation_pedestrian_human_control} src={Lookup.getPedestrianHumanControl}/>
            <Answer label="Pedestrian Crossing - Physical Facilities" value={props.accident.situation_pedestrian_physical_facilities} src={Lookup.getPedestrianPhysicalFacilities}/>
          </tbody>
        </Table>      
      </UI.Block>      
    </div>

    <div>
      <UI.Block title="Conditions">
        <Table striped transparent>
          <tbody>
            <Answer label="Weather" value={props.accident.situation_weather} src={Lookup.getWeather}/>
            <Answer label="Road Surface Condition" value={props.accident.situation_road_surface} src={Lookup.getRoadSurface}/>
            <Answer label="Light Conditions" value={props.accident.situation_light} src={Lookup.getLight}/>
          </tbody>
        </Table>      
      </UI.Block>         
      <UI.Block title="Special Conditions">
        <Table striped transparent>
          <tbody>
            {!props.accident.situation_special && <tr><td>None</td></tr>}
            {props.accident.situation_special && Lookup.getSpecialConditions.map((condition, index) => {
              return props.accident.situation_special.split(" ").includes(index.toString()) ? <tr key={index}><td>{condition}</td></tr> : null;
            })}
          </tbody>
        </Table>
      </UI.Block>   
      <UI.Block title="Other">
        <Table striped transparent>
          <tbody>
            <Answer label="Carriageway Hazards" value={props.accident.situation_carriageway_hazards} src={Lookup.getCarriagewayHazards}/>
            <Answer label="Officer Present" value={props.accident.situation_officer_present} src={Lookup.getYesNo}/>
          </tbody>
        </Table>      
      </UI.Block>                  
    </div>

  </Flex.Columns>

export { TableSituation }
