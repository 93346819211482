import * as React from 'react';
import { ISigninResponse } from '../api/auth/SigninResponse';

interface IAuth {
  uuid: string;
  access_token: string;
  name: string;
  email: string;
  rights: string[];
  signin: (data: ISigninResponse) => void;
  signout: () => void;
}

const AuthContext = React.createContext<IAuth>({
  uuid: null,
  access_token: null,
  name: null,
  email: null,
  rights: [],

  signin: () => {},
  signout: () => {}
});

export { AuthContext, IAuth }
