import { Block } from "./Block";
import { Content } from "./Content";
import { FilterBox } from "./FilterBox";
import { Footer, FooterSection } from "./Footer";
import { Header } from "./Header";
import { PaddedScreen } from "./PaddedScreen";

class UI {
  static Header = Header;
  static PaddedScreen = PaddedScreen;
  static Footer = Footer;
  static FooterSection = FooterSection;
  static Content = Content;
  static Block = Block;
  static FilterBox = FilterBox;
}

export { UI }
