import { Label } from '@independent-software/typeui/controls';
import * as React from 'react';
import styled from 'styled-components';
import { Rose } from './Rose';

interface IProps {
  /** Answer label */
  label: React.ReactNode;
  /** Answer value */
  value: any;
  /** Optional lookup table */
  src?: string[];
  /** Show compass rose? */
  rose?: boolean;
}

const Answer = (props: IProps) => {
  const renderValue = () => {
    if(props.value == null) return <Label size="small">(not set)</Label>;
    if(props.src) return props.src[props.value];
    if(props.rose) return <Rose value={props.value}/>
    return props.value;
  }
  
  return (
    <tr>
      <td>{props.label}</td>
      <td>{renderValue()}</td>
    </tr>
  )
}


export { Answer }
