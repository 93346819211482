import type { CircleLayer } from 'react-map-gl';
import { MARKER_HOVER_COLOR } from './ClusterConstants';

const MarkersCircleHoverLayer: CircleLayer = {
  id: "markers-circles-hover",
  type: "circle",
  paint: {
    "circle-radius": 9,
    "circle-color": MARKER_HOVER_COLOR
  }
};

export { MarkersCircleHoverLayer }
