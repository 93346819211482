import { ISigninResponse } from './SigninResponse';
import { Api } from '../Api';
import { App } from '../../App';

class AuthApi {

  /**
   * Sign in with email/password, and return ISigninResponse
   * @param email Email to login with
   * @param password Password to login with
   * @returns ISigninResponse contains user ID (= email), plus authorities.
   */
  static signin = async (email: string, password: string): Promise<ISigninResponse> => {
    return Api.Post<ISigninResponse>(App.config.apiURL + `signin`, { email: email, password: password }, null)
    .catch((error) => {
      if(error.response) {
        throw new Error((error.response.data as any).message);
      } else if(error.request) {
        throw new Error("No server response was received.");
      } else {
        throw new Error("There was a problem sending your request.");
      }
    });
  }

  static request = async (email: string): Promise<any> => {
    return Api.Post<any>(App.config.apiURL + `forgot`, { email: email }, null);
  }

  static reset = async (token: string, password: string): Promise<any> => {
    return Api.Post<any>(App.config.apiURL + `reset`, { token, password }, null);
  }
}

export { AuthApi }
