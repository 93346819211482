import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';

import { Button } from '@independent-software/typeui/controls/Button'
import { Icon } from '@independent-software/typeui/controls/Icon'
import { Panel } from '@independent-software/typeui/controls/Panel'
import { Label } from '@independent-software/typeui/controls/Label'
import { List } from '@independent-software/typeui/controls/List'

interface IExportProps {
  className?: string;
  /** Callback called when an export option is clicked. */
  onExport: (format: string) => void;
}

interface IState {
  open: boolean; // Panel currently open?
}

class ExportBase extends React.Component<IExportProps, IState> {
  constructor(props: IExportProps) {
    super(props);
    this.state = {
      open: false
    };
  }
  
  // Toggle panel.
  handleIconClick = () => {
    this.setState((prevState) => { return {
      open: !prevState.open
    }});
  }  

  // Close panel.
  handleClose = () => {
    this.setState({
      open: false
    });
  }  

  // An export option was selected. Inform the parent.
  handleExport = (format: string) => {
    this.setState({
      open: false
    });
    this.props.onExport(format);
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <span>
          <Button secondary size='tiny' onClick={this.handleIconClick}>
            <Label color="#000"><Icon color="#fff" name='file-download'/></Label>
            Export
          </Button>
          <Panel open={this.state.open} onClose={this.handleClose}>
            <List divided selection relaxed align="center">
              <List.Item onClick={() => this.handleExport('excel')}>
                <Icon size="large" name="file-excel"/>
                <List.Content>Microsoft Excel</List.Content>
              </List.Item>
              <List.Item onClick={() => this.handleExport('csv')}>
                <Icon size="large" name="file-csv"/>
                <List.Content>CSV</List.Content>
              </List.Item>
              <List.Item onClick={() => this.handleExport('ods')}>
                <Icon size="large" name="file-ods"/>                
                <List.Content>OpenOffice</List.Content>
              </List.Item>                        
            </List>
          </Panel>
        </span>
      </div>
    )
  }
}

const Export = styled(ExportBase)`
  position: relative;
  flex: 1;
  text-align: right;
  &>span {
    text-align: left;
  }
`

export { Export }
