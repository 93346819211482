import type { SymbolLayer } from 'react-map-gl';
import { CLUSTER_OPACITY_END, CLUSTER_OPACITY_START, CLUSTER_TEXT_COLOR } from './ClusterConstants';

const ClustersSymbolLayer: SymbolLayer = {
  id: "clusters-symbols",
  type: "symbol",
  layout: {
    "text-field": [
      "case",
      [">=", ["get", "point_count"], 1000000 ], ["concat", [ "round", ["/", ["get", "point_count"], 1000000]], "M"],
      [">",  ["get", "point_count"],    1000 ], ["concat", [ "round", ["/", ["get", "point_count"],    1000]], "K"],
      ["get", "point_count"]
    ],
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 14
  },
  paint: {
    'text-color': CLUSTER_TEXT_COLOR,
  }
};

export { ClustersSymbolLayer }
