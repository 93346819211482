import * as React from 'react';

import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';
import { Password } from '@independent-software/typeui/modules/Password';

import { ISigninRequest } from './ISigninRequest';

interface ISigninFormProps {
  /** Initial form data. */
  data: ISigninRequest;
  /** Called whenever form changes. */
  onChange: (data: ISigninRequest, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** Are form controls disabled? */
  disabled?: boolean;
  dirty?: boolean;
  onSubmit: () => void;
}

const SigninForm = (props: ISigninFormProps) => {
  const [data, setData] = React.useState<ISigninRequest>(props.data);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if(e.charCode == 13) props.onSubmit();
  }
  
  return (
    <div onKeyPress={handleKeyPress}>
      <Form data={data} dirty={props.dirty} onChange={props.onChange} onValidate={props.onValidate}>
        <Form.Field 
          required={{message: "Email is required."}}
          name="email" 
          value={data.email}
          control={(<Input disabled={props.disabled} type="text" placeholder="Email" fluid/>)}
          hint="Enter your email."
        />
        <Form.Field 
          name="password" 
          value={data.password}
          control={(<Password disabled={props.disabled} placeholder="Password" fluid/>)}
          hint="Enter your password."
        />            
      </Form>
    </div>
  );
}

export { SigninForm }
