import type { CircleLayer } from 'react-map-gl';
import { MARKER_HOVER_COLOR, MARKER_STROKE_COLOR } from './ClusterConstants';

const MarkersStrokeHoverLayer: CircleLayer = {
  id: "markers-stroke-hover-hover",
  type: "circle",
  paint: {
    "circle-radius": 12,
    "circle-opacity": 0,
    "circle-color": "transparent",
    "circle-stroke-color": MARKER_HOVER_COLOR,
    "circle-stroke-width": 6
  }
};

export { MarkersStrokeHoverLayer }
