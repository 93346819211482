import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';

import { UserForm } from './UserForm';
import { IUser } from '../../api/user/IUser';
import { UI } from '../../components/UI/UI';
import { UserApi } from '../../api/user/UserApi';
import { useAuth } from '../../auth/useAuth';

type TStep = 'ready' | 'saving' | 'saveError';

const AddUser = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const currentUser = React.useRef({ name: null, email: null, profile: null });
  const [user, setUser] = React.useState<IUser>(currentUser.current);
  const [step, setStep] = React.useState<TStep>("ready");
  const [dirty, setDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState(null)

  const handleChange = (newUser: IUser, forceupdate: boolean) => {
    currentUser.current = Object.assign(currentUser.current, newUser);
  }

  const handleValidate = (valid: boolean) => {
    setIsValid(valid);
  }  

  const handleSubmit = () => {
    setUser(currentUser.current);
    setDirty(true);
    if(!isValid) return;
    setError(null);
    setStep('saving');

    UserApi.create(currentUser.current, auth.access_token)
    .then(() => {
      ToastService.toast("User created successfully.");
      navigate("/users");
    })
    .catch(error => {
      setStep('saveError');
      setError(error);
    });
  }

  const handleCancelSave = () => {
    setStep('ready');
  }

  return (
    <>
      <UI.PaddedScreen>
        <UI.Header>Add User</UI.Header>
        {step == 'saving' && <Loader></Loader>}
        <UI.Content>
          <UserForm dirty={dirty} requirePassword data={user} onChange={handleChange} onValidate={handleValidate}/>
        </UI.Content>
        <UI.Footer>
          <Button secondary onClick={() => navigate("/users")}>Cancel</Button>
          <Button primary disabled={!isValid} onClick={handleSubmit}><Icon name="save"/> Save user</Button>
        </UI.Footer>
      </UI.PaddedScreen>
      <Dialog.Xhr open={step == 'saveError'} error={error} onClose={handleCancelSave} onRetry={handleSubmit}/>
    </>
  );
}

export { AddUser };
