import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const MapHolderBase = (props: IProps) => 
  <div className={props.className}>
    {props.children}
  </div>

const MapHolder = styled(MapHolderBase)`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background: #333;
`

export { MapHolder }
