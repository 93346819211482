import * as React from 'react';

import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';

interface IProps {
  /** Initial form data. */
  data: {email: string};
  /** Called whenever form changes. */
  onChange: (data: { email: string }, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** Are form controls disabled? */
  disabled?: boolean;
  dirty?: boolean;
  onSubmit: () => void;
}

const ForgotForm = (props: IProps) => {
  const [data, setData] = React.useState<{email: string}>(props.data);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if(e.charCode == 13) props.onSubmit();
  }
  
  return (
    <div onKeyPress={handleKeyPress}>
      <Form data={data} dirty={props.dirty} onChange={props.onChange} onValidate={props.onValidate}>
        <Form.Field 
          required={{message: "Email is required."}}
          name="email" 
          value={data.email}
          control={(<Input disabled={props.disabled} type="text" placeholder="Email" fluid/>)}
          hint="Enter your email."
        />
      </Form>
    </div>
  );
}

export { ForgotForm }
