import * as React from 'react';

import { Input } from '@independent-software/typeui/controls/Input';
import { DmsInput } from '@independent-software/typeui/modules/DmsInput';
import { Form } from '@independent-software/typeui/controls/Form';
import { Flex } from '@independent-software/typeui/controls';
import { UI } from '../../../components/UI/UI';
import { IAccident } from '../../../api/accident/IAccident';
import { Lookup } from '../Lookup';

interface IProps {
  /** Initial form props.data. */
  data: IAccident;  
}

const FormLocation = (props: IProps) => 
  <Flex.Columns count={2}>
          
  <div>
    <UI.Block title="Date and Time">
      <Form.Field 
        required={{ message: "Date is required."}}
        width={1} label="Date"
        name="date" 
        value={props.data.date}
        control={(<Input type="date" placeholder="Date" fluid/>)}
        hint="Please select the date on which the accident occurred."/>          
      <Form.Field 
        required={{ message: "Time is required."}}
        width={1} label="Time"
        name="time" 
        value={props.data.time}
        control={(<Input type="time" clock placeholder="Time" fluid/>)}
        hint="Please select the time at which the accident occurred."/>                  
    </UI.Block>

    <UI.Block title={<>GPS</>}>
      <Form.Field 
        required={{ message: "Latitude is required."}}
        isFloat={{ message: "Latitude must be a fractional number."}}
        width={1} label="Latitude"
        name="latitude" 
        value={props.data.latitude}
        control={(<DmsInput isLatitude/>)}
        hint="Please enter the latitude at which the accident occurred."/>    

      <Form.Field 
        required={{ message: "Longitude is required."}}
        isFloat={{ message: "Longitude must be a fractional number."}}
        width={1} label="Longitude"
        name="longitude" 
        value={props.data.longitude}
        control={(<DmsInput isLatitude={false}/>)}
        hint="Please enter the longitude at which the accident occurred."/>    
    </UI.Block>

    <UI.Block title="Town details">

      <Form.Field 
        maxLength={{length: 255, message: "Town name may not be longer than 255 characters."}}
        width={1} label="Town / Nearest Town"
        name="general_town" 
        value={props.data.general_town}
        control={(<Input type="text" placeholder="Town" fluid/>)}
        hint="Please enter the name of the (nearest) town."/>   

      <Form.Field 
        maxLength={{length: 255, message: "Village name may not be longer than 255 characters."}}
        width={1} label="Village / Ward"
        name="general_county" 
        value={props.data.general_county}
        control={(<Input type="text" placeholder="Village / Ward" fluid/>)}
        hint="Please enter the name of the village or ward."/>   

      <Form.Field 
        maxLength={{length: 255, message: "Region name may not be longer than 255 characters."}}
        width={1} label="Region"
        name="general_parish" 
        value={props.data.general_parish}
        control={(<Input type="text" placeholder="Region" fluid/>)}
        hint="Please enter the name or number of the region."/>

      <Form.Field
        width={1} label="Police Division"
        name="general_division"
        value={props.data.general_division}
        control={Lookup.getDropdown(Lookup.getDivisions, "Police Division")}
        hint="Please select the Police Division."/>

    </UI.Block>
  </div>

  <div>
    <UI.Block title={<>1st Road</>}>

      <Form.Field 
        maxLength={{length: 255, message: "Road class may not be longer than 255 characters."}}
        width={1} label={<>1st Road Class & No.</>}
        name="general_road1_class" 
        value={props.data.general_road1_class}
        control={(<Input type="text" placeholder="Road Class" fluid/>)}
        hint={(<>Please enter the 1st road class, or <em>Unclassified</em> <code>UC</code> or <em>Not Known</em> <code>NK</code>.</>)}/>          

      <Form.Field 
        maxLength={{length: 255, message: "Road name may not be longer than 255 characters."}}
        width={1} label={<>1st Road Name</>}
        name="general_road1_name" 
        value={props.data.general_road1_name}
        control={(<Input type="text" placeholder="Road Name" fluid/>)}
        hint="Please enter the 1st road name."/>          

      <Form.Field 
        maxLength={{length: 255, message: "House number may not be longer than 255 characters."}}
        width={1} label="Outside House No."
        name="general_house_number" 
        value={props.data.general_house_number}
        control={(<Input type="text" placeholder="House Number" fluid/>)}
        hint="Please enter the house number, or name, or marker post number."/>    

      </UI.Block>
      <UI.Block title={<>2nd Road</>}>

      <Form.Field 
        isInt={{ message: "Distance must be a whole number."}}
        min={{ value: 0, message: "Distance must be at least 0."}}
        max={{ value: 99999, message: "Distance may not be greater than 99999."}}
        width={1} label="Distance"
        name="general_distance" 
        value={props.data.general_distance}
        control={(<Input type="text" placeholder="Distance" fluid/>)}
        hint="Please enter the distance to junction with 2nd road (in meters)."/>    

      <Form.Field 
        maxLength={{length: 255, message: "Road class may not be longer than 255 characters."}}
        width={1} label={<>2nd Road Class & No.</>}
        name="general_road2_class" 
        value={props.data.general_road2_class}
        control={(<Input type="text" placeholder="Road Class" fluid/>)}
        hint={(<>Please enter the 2nd road class, or <em>Unclassified</em> <code>UC</code> or <em>Not Known</em> <code>NK</code>.</>)}/>          

      <Form.Field 
        maxLength={{length: 255, message: "Road name may not be longer than 255 characters."}}
        width={1} label={<>2nd Road Name</>}
        name="general_road2_name" 
        value={props.data.general_road2_name}
        control={(<Input type="text" placeholder="Road Name" fluid/>)}
        hint="Please enter the 2nd road name."/>          

      </UI.Block>    
  </div>


</Flex.Columns>

export { FormLocation }
