import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Button position (0-2, 0-2) */
  x: number;
  y: number;
  /** Tooltip */
  title: string;
  /** Button currently depressed? */
  selected?: boolean;
  /** Fired when button is clicked. */
  onClick?: () => void;
}

const RoseButtonBase = (props: IProps) => {
  return (
    <div className={props.className} title={props.title} onClick={props.onClick ? props.onClick : null}>
      {props.children}
    </div>
  )
}

const RoseButton = styled(RoseButtonBase)`
  position: absolute;
  width: 27px;
  height: 27px;
  left: ${p => p.x * 33}px;
  top: ${p => p.y * 33}px;
  border: solid 1px ${p => p.theme.normalColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  ${p => p.onClick && css`
    cursor: pointer;
    &:hover {
      background-color: ${p => p.theme.primaryColor};
      border-color: ${p => p.theme.primaryColor};
      color: #fff;
    }
  `}
  ${p => p.selected && css`
    background-color: #333;
    border-color: #333;
    color: #fff;
    ${p.onClick && css`
      &:hover {
        background-color: #666;
        border-color: #666;
      }
    `}
  `}
`

export { RoseButton }

