import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, Icon, Label, Loader, Placeholder, Table, Tabs, ToastService } from '@independent-software/typeui/controls';

import { UI } from '../../components/UI/UI';
import { IUser } from '../../api/user/IUser';
import { UserApi } from '../../api/user/UserApi';
import { useAuth } from '../../auth/useAuth';
import { Timestamp } from '../../controls/Timestamp';
import { LogList } from '../log/LogList';

type TStep = 'loading' | 'ready' | 'confirm' | 'loadError' | 'deleting' | 'deleteError';

const ViewUser = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  const [step, setStep] = React.useState<TStep>('ready');
  const [user, setUser] = React.useState<IUser>();
  const [error, setError] = React.useState(null);
  const [logCount, setLogCount] = React.useState<number>(null);

  const loadUser = () => {
    setStep('loading');
    setError(null);
    UserApi.get(params.uuid, auth.access_token)
    .then(res => {
      setUser(res);
      setStep('ready');
    })
    .catch((err) => {
      setStep('loadError');
      setError(err);
    });
  }

  React.useEffect(() => loadUser(), []);

  const handleDelete = () => {
    setStep('deleting');
    UserApi.delete(user.uuid, auth.access_token)
    .then(res => {
      ToastService.toast("User deleted successfully.");
      navigate("/users");
    })
    .catch((err) => {
      setStep('deleteError');
      setError(err);
    });
  }
  
  return (
    <>
      {(step == 'loading' || step == 'deleting') && <Loader/>}
      <UI.PaddedScreen>
        <UI.Header>View User</UI.Header>
        <Tabs underlined>
            <Tabs.Pane label="User">        
              <UI.Content>
                <UI.Block title="User data">
                  {user == null && 
                    <Placeholder.Paragraph>
                      <Placeholder.Line/>
                      <Placeholder.Line/>
                      <Placeholder.Line/>
                      <Placeholder.Line/>
                    </Placeholder.Paragraph>}
                  {user != null && <Table striped transparent>
                    <tbody>
                      <tr>
                        <td>Name</td><td>{user.name ? user.name : <Label size="small">(not set)</Label>}</td>
                      </tr>
                      <tr>
                        <td>Email</td><td>{user.email ? user.email : <Label size="small">(not set)</Label>}</td>
                      </tr>
                      <tr>
                        <td>Profile</td><td>{user.profile.name}</td>
                      </tr>
                    </tbody>
                  </Table>}
                </UI.Block>
              </UI.Content>
            </Tabs.Pane>
            <Tabs.Pane label={<>Log {logCount !== null && <Label size="small">{logCount}</Label>}</>}>
              {user != null && <LogList userUUID={user.uuid} countCallback={(count) => setLogCount(count)}/>}
            </Tabs.Pane>
          </Tabs>
        <UI.Footer>
          <UI.FooterSection>
            {auth.rights.includes('can_edit_users') && <>
              {step != 'confirm' && <Button negative disabled={step == 'deleting'} onClick={() => setStep('confirm')}><Icon name="trash"/> Delete user</Button>}
              {step == 'confirm' && <Button negative onClick={handleDelete}><Icon name="trash"/> Are you sure?</Button>}
            </>}
            {user ? <Timestamp model={user}/> : null}
          </UI.FooterSection>
          <UI.FooterSection>
            {auth.rights.includes('can_edit_users') && <Button primary onClick={() => navigate(`/user/edit/${user.uuid}`)}><Icon name="edit"/> Edit user</Button>}
          </UI.FooterSection>
        </UI.Footer>
      </UI.PaddedScreen>

      <Dialog.Xhr open={step == 'loadError'}   error={error} onClose={() => setStep('ready')} onRetry={loadUser}/>
      <Dialog.Xhr open={step == 'deleteError'} error={error} onClose={() => setStep('ready')} onRetry={handleDelete}/>
    </>
  )

}

export { ViewUser }
