import * as React from 'react'
import styled from '@independent-software/typeui/styles/Theme';
import { Link } from 'react-router-dom';

interface IProps {
  /** @ignore */
  className?: string;
  /** Title to shown on menu (when expanded) */
  title: React.ReactNode;
  /** Fired when button is clicked. */
  onClick: () => void;
  /** Svg to show, e.g. "campaigns" */
  svg: string;
}

const MenuIconBase = (props: IProps) => {
  return (
    <div className={props.className} onClick={props.onClick}>
      <Content>
        <svg>
          <use xlinkHref={`sprites.svg#${props.svg}`}></use>
        </svg>
        {props.title}
      </Content>
    </div>
  );
}

const Content = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap;
  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    fill: #fff;
  }
  height: 48px;
`

const MenuIcon = styled(MenuIconBase)`
  display: block;
  background: ${p => p.theme.primaryColor};
  width: auto;
  max-width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 16px;
  overflow: hidden;
  color: white;
  box-shadow: 0px 3px 8px rgb(0,0,0,0.3);
  padding-left: 12px;
  padding-right: 24px;
  box-sizing: border-box;
  text-decoration: none;
  white-space: nowrap;
  
  transition: background-color ease-in-out 150ms,
              max-width 250ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  &:hover {
    background: #45bbf2;
    max-width: 500px;
  }
`

export { MenuIcon }
