import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Navigate, Outlet, useOutlet } from 'react-router-dom';

import { useAuth } from '../auth/useAuth';
import { MainMenu } from '../components/MainMenu';
import { Filter } from '../contexts/Filter';
import { Home } from './home/Home';

interface IProps {
  className?: string;
}

const ProtectedRouteBase = (props: IProps) => {
  const auth = useAuth();
  const outlet = useOutlet();

  if (auth.access_token == null) {
    return <Navigate to="/signin" replace />
  }

  return (
    <div className={props.className}>
      <MainMenu/>
      <Content>
        <Filter>
          {/* Home (with map) is alway shown, so it doesn't lose state. */}
          <Home/>
          {/* Something else may be rendered on top of the map -- a child route. */}
          {outlet &&
            <Overlay>
              <Outlet/>
            </Overlay>}
        </Filter>
      </Content>
    </div>
  );
}

const Content = styled.div`
  position: absolute;
  left: 75px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #fff;
`

const ProtectedRoute = styled(ProtectedRouteBase)`
  position:    absolute;
  left:        0;
  top:         0;
  width:       100vw;
  height:      100vh;
  overflow:    hidden;  
`

export { ProtectedRoute }
