import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const HeaderBase = (props: IProps) => 
  <div className={props.className}>
    {props.children}
  </div>

const Header = styled(HeaderBase)`
  padding: 16px 0 16px 16px;
  font-size: 32px;
  font-weight: 500;
  background: #fafafa;
  color: #555;
  border-bottom: solid 1px #ddd;
`

export { Header }
