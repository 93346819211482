import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@independent-software/typeui/controls';

import { useAuth } from '../../auth/useAuth';
import { UI } from '../../components/UI/UI';
import { ProfileList } from './ProfileList';

const Profiles = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <UI.PaddedScreen>
      <UI.Header>Profiles</UI.Header>
      <ProfileList/>
      <UI.Footer>
        <div></div>
        {auth.rights.includes('can_edit_profiles') && <Button primary onClick={() => navigate("/profile/add")}>Add profile</Button>}
      </UI.Footer>
    </UI.PaddedScreen>
  )
}

export { Profiles }
