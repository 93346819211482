import * as React from 'react';
import { Flex, Label, Table } from '@independent-software/typeui/controls';
import { Latitude, Longitude } from '@independent-software/typeui/formatters/GIS'

import { IAccident } from '../../../api/accident/IAccident';
import { UI } from '../../../components/UI/UI';
import { Answer } from '../controls/Answer';
import { Lookup } from '../Lookup';
import { format, parseISO } from 'date-fns';

interface IProps {
  accident: IAccident;
}

const TableLocation = (props: IProps) => 
  <Flex.Columns count={2}>
    
    <div>
      <UI.Block title="Base">
        <Table striped transparent>
          <tbody>
            <Answer label="Kobo user name" value={props.accident.username}/>
            <Answer label="date" value={format(parseISO(props.accident.date), "dd MMMM yyyy")}/>
            <Answer label="time" value={props.accident.time}/>
            <Answer label="Latitude" value={<><Label><Latitude value={props.accident.latitude}/></Label> <Label>{props.accident.latitude}</Label></>}/>
            <Answer label="Longitude" value={<><Label><Longitude value={props.accident.longitude}/></Label> <Label>{props.accident.longitude}</Label></>}/>
          </tbody>
        </Table>      
      </UI.Block>
      <UI.Block title="Town details">
        <Table striped transparent>
          <tbody>
            <Answer label="Town / Nearest Town" value={props.accident.general_town}/>
            <Answer label="Village / Ward" value={props.accident.general_county}/>
            <Answer label="Region" value={props.accident.general_parish}/>
            <Answer label="Police Division" value={props.accident.general_division} src={Lookup.getDivisions}/>
          </tbody>
        </Table>
      </UI.Block>         
    </div>

    <div>
      <UI.Block title="1st Road">
        <Table striped transparent>
          <tbody>
            <Answer label="1st Road Class & No." value={props.accident.general_road1_class}/>
            <Answer label="1st Road Name" value={props.accident.general_road1_name}/>
            <Answer label="Outside House No." value={props.accident.general_house_number}/>
          </tbody>
        </Table>
      </UI.Block>

      <UI.Block title="2nd Road">
        <Table striped transparent>
          <tbody>
            <Answer label="Distance (m)" value={props.accident.general_distance}/>
            <Answer label="2nd Road Class & No." value={props.accident.general_road2_class}/>
            <Answer label="2nd Road Name" value={props.accident.general_road2_name}/>
          </tbody>
        </Table>
      </UI.Block>      
    </div>

  </Flex.Columns>

export { TableLocation }
