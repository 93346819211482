import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable, Dropdown, Form, Input } from '@independent-software/typeui/controls';
import { TDir } from '@independent-software/typeui/controls/Types';
import { Datum } from '@independent-software/typeui/formatters/Datum';

import { useAuth } from '../../auth/useAuth';
import { IUser } from '../../api/user/IUser';
import { UserApi } from '../../api/user/UserApi';
import { ProfileApi } from '../../api/profile/ProfileApi';
import { IProfile } from '../../api/profile/IProfile';
import { UI } from '../../components/UI/UI';
import { Highlight } from '../../controls/Highlight';

interface IProps {
  profileUUID?: string;
  countCallback?: (count: number) => void;
}

const UserList = (props: IProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<string>("email");
  const [dir, setDir] = React.useState<TDir>('asc');
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [profiles, setProfiles] = React.useState<IProfile[]>([]);
  const [profile, setProfile] = React.useState<IProfile>(null);
  const [q, setQ] = React.useState<string>(null);
  const [loading, setLoading] = React.useState(false);

  const loadProfiles = () => {
    ProfileApi.list('name', 'asc', 0, 50, null, auth.access_token)
    .then(res => setProfiles(res.data));
  }

  React.useEffect(() => handleFetch(0, 33, true), [order, dir, q, profile]);
  React.useEffect(loadProfiles, []);

  const handleChangeQ = (value: string) => {
    setQ(value);
  }

  const handleOrder = (newOrder: string, newDir: TDir) => {
    setOrder(newOrder);
    setDir(newDir);
    if(order === newOrder) setDir(dir === 'asc' ? 'desc' : 'asc');
  }

  const handleFetch = (offset: number, count: number, erase?: boolean) => {
    setLoading(true);
    UserApi.list(order, dir, offset, count, q, props.profileUUID ? props.profileUUID : (profile ? profile.uuid : null), auth.access_token)
    .then(res => {
      // Notify subscriber that count has changed:
      if(props.countCallback) props.countCallback(res.count);
      let newItems = users.slice();
      // Erase all items if count is different, or the filter changed:
      if(erase || res.count != users.length) {
        newItems = Array(res.count).fill(null);
      }
      // Copy fetch results into array:
      res.data.forEach((u, index) => {
        newItems[offset + index] = u;
      });
      setUsers(newItems);
    })
    .finally(() => setLoading(false));
  }

  const handleSelectUser = (user: IUser) => {
    navigate(`/user/${user.uuid}`);
  }

  return (
    <>
      <UI.FilterBox>
        <Form.Uncontrolled hint="Type to filter users">
          <Input clearable value={q} type="text" placeholder="Search users..." onChange={handleChangeQ}/>
        </Form.Uncontrolled>
        {!props.profileUUID && <Form.Uncontrolled hint="Filter users by profile">
          <Dropdown clearable data={profiles} placeholder="Select profile" label={(item: IProfile) => item.name} value={profile} onChange={setProfile}>
            <Dropdown.Column>{(item: IProfile) => item.name}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>}
      </UI.FilterBox>
      <DataTable loading={loading} data={users} order={order} dir={dir} onOrder={handleOrder} onFetch={handleFetch} onClick={handleSelectUser}>
        <DataTable.Column weight={2} label="Email" order="email" dir="asc">
          {(item:IUser) => <Highlight value={item.email} q={q}/>}
        </DataTable.Column>
        <DataTable.Column weight={2} label="Name" order="name" dir="asc">
          {(item:IUser) => <Highlight value={item.name} q={q}/>}
        </DataTable.Column>
        <DataTable.Column weight={1} label="Last seen" order="login_at" dir="desc">
          {(item: IUser) => item.login_at ? <Datum.Distance value={item.login_at}/> : 'never' }
        </DataTable.Column>
        <DataTable.Column weight={1} label="Profile" dir="asc">{(item:IUser) => item.profile.name}</DataTable.Column>
      </DataTable>  
    </>
  )
}

export { UserList }
