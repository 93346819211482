import * as React from 'react';

import { DataTable, Dropdown, Form, Gravatar, Input } from '@independent-software/typeui/controls';
import { Datum } from '@independent-software/typeui/formatters/Datum';
import { TDir } from '@independent-software/typeui/controls/Types';

import { useAuth } from '../../auth/useAuth';
import { ILog } from '../../api/log/ILog';
import { LogApi } from '../../api/log/LogApi';
import { IUser } from '../../api/user/IUser';
import { UserApi } from '../../api/user/UserApi';
import { UI } from '../../components/UI/UI';
import { Highlight } from '../../controls/Highlight';

interface IProps {
  userUUID?: string;
  countCallback?: (count: number) => void;
}

const LogList = (props: IProps) => {
  const auth = useAuth();
  const [order, setOrder] = React.useState<string>("created_at");
  const [dir, setDir] = React.useState<TDir>('desc');
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [user, setUser] = React.useState<IUser>(null);
  const [logs, setLogs] = React.useState<ILog[]>([]);
  const [q, setQ] = React.useState<string>(null);
  const [fromDate, setFromDate] = React.useState<string>(null);
  const [toDate, setToDate] = React.useState<string>(null);
  const [loading, setLoading] = React.useState(false);
  const [userQ, setUserQ] = React.useState<string>(null);

  React.useEffect(() => handleFetch(0, 33, true), [order, dir, q, fromDate, toDate, user]);

  React.useEffect(() => {
    UserApi.list('name', 'asc', 0, 20, userQ, null, auth.access_token)
    .then(res => {
      setUsers(res.data);
    })
    .catch(() => {
    });
  }, [userQ]);

  const handleChangeQ = (value: string) => {
    setQ(value);
  }

  const handleChangeFromDate = (value: string) => {
    setFromDate(value);
  }

  const handleChangeToDate = (value: string) => {
    setToDate(value);
  }

  const handleChangeUser = (value: IUser) => {
    setUser(value);
  }

  const handleChangeUserQ = (value: string) => {
    setUserQ(value);
  }

  const handleOrder = (newOrder: string, newDir: TDir) => {
    setOrder(newOrder);
    setDir(newDir);
    if(order === newOrder) setDir(dir === 'asc' ? 'desc' : 'asc');
  }

  const handleFetch = (offset: number, count: number, erase?: boolean) => {
    setLoading(true);
    let uuid = null;
    if(props.userUUID) uuid = props.userUUID;
    if(user) uuid = user.uuid;
    LogApi.list(uuid, order, dir, offset, count, q, fromDate, toDate, auth.access_token)
    .then(res => {
      // Notify subscriber that count has changed:
      if(props.countCallback) props.countCallback(res.count);      

      let newItems = logs.slice();
      // Erase all items if count is different, or the filter changed:
      if(erase || res.count != logs.length) {
        newItems = Array(res.count).fill(null);
      }
      // Copy fetch results into array:
      res.data.forEach((u, index) => {
        newItems[offset + index] = u;
      });
      setLogs(newItems);
    })
    .finally(() => setLoading(false));
  }

  return (
    <>
      <UI.FilterBox>
        <Form.Uncontrolled hint="Type to filter log entries">
          <Input clearable value={q} type="text" placeholder="Search logs..." onChange={handleChangeQ}/>
        </Form.Uncontrolled>
        {!props.userUUID && 
          <Form.Uncontrolled hint="Filter logs by user">
            <Dropdown onSearch={handleChangeUserQ} name="user" clearable value={user} data={users} placeholder="User" label={(item:IUser) => item.name ? item.name : item.email} onChange={handleChangeUser}>
              <Dropdown.Column>{(item:IUser) => item.name ? item.name : item.email}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}           
        <Form.Uncontrolled hint="Filter logs by start date">
          <Input name="from" type="date" clearable value={fromDate} placeholder="From date" onChange={handleChangeFromDate}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="Filter logs by end date">
          <Input name="to" type="date" clearable value={toDate} placeholder="To date" onChange={handleChangeToDate}/>
        </Form.Uncontrolled>          
      </UI.FilterBox>
      <DataTable loading={loading} data={logs} order={order} dir={dir} onOrder={handleOrder} onFetch={handleFetch}>
        {!props.userUUID && <DataTable.Column weight={1} label="User" order="username" dir="asc">{(item:ILog) => item.username ? <Highlight value={item.username} q={q}/> : (<span style={{color:'#aaa'}}>(user deleted)</span>)}</DataTable.Column>}
        <DataTable.Column weight={2} label="Type" order="type" dir="asc">{(item:ILog) => <Highlight value={item.type} q={q}/>}</DataTable.Column>
        <DataTable.Column weight={2} label="Operation" order="operation" dir="asc">{(item:ILog) => <Highlight value={item.operation} q={q}/>}</DataTable.Column>
        <DataTable.Column weight={2} label="Value" order="name" dir="asc">{(item:ILog) => <Highlight value={item.name} q={q}/>}</DataTable.Column>
        <DataTable.Column weight={1} label="Date/time" order="created_at" dir="desc">{(item:ILog) => <Datum.Distance value={item.created_at}/>}</DataTable.Column>
      </DataTable>  
    </>
  )
}



export { LogList }
