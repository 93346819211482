import * as React from 'react';

import { Form } from '@independent-software/typeui/controls/Form';
import { Textarea } from '@independent-software/typeui/controls';
import { IAccident } from '../../../api/accident/IAccident';

interface IProps {
  /** Initial form props.data. */
  data: IAccident;  
}

const FormComment = (props: IProps) => 
  <div>
    <Form.Field 
      width={1} label="Comments"
      name="general_comment" 
      value={props.data.general_comment}
      control={(<Textarea rows={20} placeholder="Comments" fluid/>)}
      hint="Please use this space to provide any additional comments."/>   
  </div>

export { FormComment }
