import * as React from 'react';
import { Segment } from '@independent-software/typeui/controls/Segment';

interface IProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  secondary?: boolean;
  tertiary?: boolean;
}

const Block = (props: IProps) => 
  <>
    <Segment raised secondary={props.secondary} tertiary={props.tertiary} attached="top">
      {props.title}
    </Segment>
    <Segment raised attached="bottom">
      {props.children}
    </Segment>
  </>

export { Block }
