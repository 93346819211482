import * as React from 'react';
import { MapboxMap, ViewState } from 'react-map-gl';
import { Dialog } from '@independent-software/typeui/controls';

import { useAuth } from '../auth/useAuth';
import { FilterContext, IFilter, TTableState } from './FilterContext';
import { AccidentApi } from '../api/accident/AccidentApi';
import { IAccidentProxy } from '../api/accident/IAccidentProxy';

interface IProps {
  children?: React.ReactNode;
}

const Filter = (props: IProps) => {
  const auth = useAuth();
  const retry = React.useRef(null);
  const giveup = React.useRef(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [tableState, setTableState] = React.useState<TTableState>('closed');
  const [accidents, setAccidents] = React.useState<IAccidentProxy[]>([]);
  const [viewState, setViewState] = React.useState<ViewState>({
    longitude: -59.46, 
    latitude: 4.84, 
    zoom: 6,
    bearing: 0,
    pitch: 45,
    padding: { top: 0, bottom: 0, right: 0, left: 0 }
  });
  const [map, setMap] = React.useState<MapboxMap>(null);
  const [filterDateFrom, setFilterDateFrom] = React.useState<string>(null);
  const [filterDateTo, setFilterDateTo] = React.useState<string>(null);
  const [filterVehicleType, setFilterVehicleType] = React.useState<string>(null);
  const [filterDriverAge, setFilterDriverAge] = React.useState<string>(null);
  const [filterCasualtyClass, setFilterCasualtyClass] = React.useState<string>(null);
  const [filterCasualtyAge, setFilterCasualtyAge] = React.useState<string>(null);
  const [filterCasualtySeverity, setFilterCasualtySeverity] = React.useState<string>(null);
  const [filterBreathTestPositive, setFilterBreathTestPositive] = React.useState<boolean>(null);

  React.useEffect(() => loadAccidents(), [filterDateFrom, filterDateTo, filterVehicleType, filterDriverAge, filterCasualtyClass, filterCasualtyAge, filterCasualtySeverity, filterBreathTestPositive]);

  // Load projects. Executed as soon as page loads.
  // If there are any projects that the current user can access, then this
  // will also load the first project.
  const loadAccidents = () => {
    setError(null);
    setLoading(true);
    retry.current = () => loadAccidents();
    giveup.current = () => {};
    AccidentApi.list("accidents.uuid", "asc", filterDateFrom, filterDateTo, filterVehicleType, filterDriverAge, filterCasualtyClass, filterCasualtyAge, filterCasualtySeverity, filterBreathTestPositive, auth.access_token)
    .then((res) => {
      setAccidents(res.data);
    })
    .catch((err) => setError(err))
    .finally(() => setLoading(false));
  }

  const handleExport = (format: string) => {
    AccidentApi.export(format, "accidents.uuid", "asc", filterDateFrom, filterDateTo, filterVehicleType, filterDriverAge, filterCasualtyClass, filterCasualtyAge, filterCasualtySeverity, filterBreathTestPositive, auth.access_token);
  }

  const handleAddAccident = (accident: IAccidentProxy) => {
    setAccidents([...accidents, accident]);
  }

  const handleUpdateAccident = (accident: IAccidentProxy) => {
    const newAccidents = [ ...accidents.filter(a => a.uuid != accident.uuid), accident ];
    setAccidents(newAccidents);    
  }

  const handleRemoveAccident = (accident: IAccidentProxy) => {
    setAccidents(accidents.filter(a => a.uuid != accident.uuid));
  }

  const handleSetTableState = (state: TTableState) => {
    setTableState(state);
  }

  const handleSetViewState = (state: ViewState) => {
    setViewState(state);
  }

  const handleSetMap = (map: MapboxMap) => {
    setMap(map);
  }

  const value: IFilter = {
    accidents: accidents,
    tableState: tableState,
    setTableState: handleSetTableState,
    viewState: viewState,
    setViewState: handleSetViewState,
    map: map,
    setMap: handleSetMap,
    addAccident: handleAddAccident,
    updateAccident: handleUpdateAccident,
    removeAccident: handleRemoveAccident,
    loading: loading,
    handleExport: handleExport,

    filterDateFrom: filterDateFrom,
    setFilterDateFrom: setFilterDateFrom,
    filterDateTo: filterDateTo,
    setFilterDateTo: setFilterDateTo,
    filterVehicleType: filterVehicleType,
    setFilterVehicleType: setFilterVehicleType,
    filterDriverAge: filterDriverAge,
    setFilterDriverAge: setFilterDriverAge,
    filterCasualtyClass: filterCasualtyClass,
    setFilterCasualtyClass: setFilterCasualtyClass,
    filterCasualtyAge: filterCasualtyAge,
    setFilterCasualtyAge: setFilterCasualtyAge,
    filterCasualtySeverity: filterCasualtySeverity,
    setFilterCasualtySeverity: setFilterCasualtySeverity,
    filterBreathTestPositive: filterBreathTestPositive,
    setFilterBreathTestPositive: setFilterBreathTestPositive
  }

  return (
    <FilterContext.Provider value={value}>
      {/* {loading && <Loader/>} */}
      {props.children}
      <Dialog.Xhr open={error != null} error={error} onRetry={retry.current} onClose={giveup.current}/>
    </FilterContext.Provider>
  );
}

export { Filter }
