import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { useNavigate } from 'react-router-dom';

import { Button } from '@independent-software/typeui/controls/Button'

const NoMatch = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  }

  return (
    <div>
      <Heading>Route not found</Heading>
      The route you're following does not exist.
      <Buttons>
        <Button primary onClick={handleGoHome}>Go home</Button>
      </Buttons>
    </div>
  )
}

const Heading = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #616A7C;
  margin-bottom: 8px;
`  

const Buttons = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: end;
`

export { NoMatch }
