import axios from 'axios';
import { App } from '../App';

/**
 * The Api class simplifies calls to Axios. It offers Get, Post, Put and
 * Delete methods, which can be typed. 
 * 
 * Api furtheroffers variants like ApiGet (call GET on the regular api) and
 * AdminGet (call GET on the admin api).
 * 
 * @example
 * Api.AdminGet("user", "s8g8w45eg7fdg7fdg7d8t45")
 * .then(res => console.log(res));
 */
class Api {
  /**
   * Perform a GET request of type T at given URL. This method takes care of
   * sending the access token along. If `null` is specified for the access
   * token, no Authorization header is sent.
   * 
   * @param url URL to retrieve
   * @param access_token Optional access token. Set to null for no access token.
   * @param signal Optional abort signal
   * @returns Promise
   */
  public static async Get<T> (url: string, access_token: string, signal?: AbortSignal): Promise<T> {
    let headers = {};
    if(access_token != null) (headers as any).Authorization = `Bearer ${access_token}`;    
    const response = await axios.get<T>(url, {
      signal: signal,
      headers: headers
    });
    return response.data;
  }

  /**
   * Perform a POST request of type T at given URL. This method takes care of
   * sending the access token along. If `null` is specified for the access
   * token, no Authorization header is sent.
   * 
   * @param url URL to access
   * @param request Object with POST information
   * @param access_token Optional access token. Set to null for no access token.
   * @param signal Optional abort signal
   * @returns Promise
   */
  public static async Post<T>(url: string, request: any, access_token: string, signal?: AbortSignal): Promise<T> {
    let headers = {};
    if(access_token != null) (headers as any).Authorization = `Bearer ${access_token}`;
    const response = await axios.post<T>(url, request, {
      signal: signal,
      headers: headers
    });
    return response.data;
  }  

  public static async PostWithFormData<T>(url: string, request: any, access_token: string, signal?: AbortSignal): Promise<T> {
    let headers = {
      'Content-Type': 'multipart/form-data'
    };
    if(access_token != null) (headers as any).Authorization = `Bearer ${access_token}`;
    const response = await axios.post<T>(url, request, {
      signal: signal,
      headers: headers
    });
    return response.data;
  }  


  /**
   * Perform a PUT request of type T at given URL. This method takes care of
   * sending the access token along. If `null` is specified for the access
   * token, no Authorization header is sent.
   * 
   * @param url URL to access
   * @param request Object with PUT information
   * @param access_token Optional access token. Set to null for no access token.
   * @param signal Optional abort signal
   * @returns Promise
   */
  public static async Put<T>(url: string, request: any, access_token: string, signal?: AbortSignal): Promise<T> {
    let headers = {};
    if(access_token != null) (headers as any).Authorization = `Bearer ${access_token}`;    
    const response = await axios.put<T>(url, request, {
      signal: signal,
      headers: headers
    });
    return response.data;
  }  

  /**
   * Perform a DELETE request of type T at given URL. This method takes care of
   * sending the access token along. If `null` is specified for the access
   * token, no Authorization header is sent.
   * 
   * @param url URL to access
   * @param access_token Optional access token. Set to null for no access token.
   * @param signal Optional abort signal
   * @returns Promise
   */
  public static async Delete<T>(url: string, access_token: string, signal?: AbortSignal): Promise<T> {
    let headers = {};
    if(access_token != null) (headers as any).Authorization = `Bearer ${access_token}`;        
    const response = await axios.delete<T>(url, {
      signal: signal,
      headers: headers
    });
    return response.data;
  }
}

export { Api }
