import * as React from 'react';
import styled from 'styled-components';
import { Checkbox, Divider, Dropdown, Flex, Form, Input } from '@independent-software/typeui/controls';

import { useFilter } from '../../contexts/useFilter';
import { TableButton } from './TableButton';
import { Lookup } from '../accidents/Lookup';

interface IProps {
  className?: string;
}

const FilterPanelBase = (props: IProps) => {
  const filter = useFilter();

  const handleTable = () => {
    if(filter.tableState == 'closed') {
      filter.setTableState('open');
    }
    else if(filter.tableState == 'open') {
      filter.setTableState('full');
    } else {
      filter.setTableState('closed');
    }
  }

  return (
    <div className={props.className}>
      <Header>FILTER ACCIDENTS</Header>

      <Form.Uncontrolled label="Date from">
        <Input fluid clearable type="date" value={filter.filterDateFrom} onChange={filter.setFilterDateFrom} placeholder="Start date"/>
      </Form.Uncontrolled>
      <Form.Uncontrolled label="Date to">
        <Input fluid clearable type="date" value={filter.filterDateTo} onChange={filter.setFilterDateTo} placeholder="End date"/>
      </Form.Uncontrolled>

      <Divider>Vehicle</Divider>

      <Form.Uncontrolled label="Vehicle">
        <Dropdown data={Lookup.keys(Lookup.getVehicleType)} fluid clearable value={filter.filterVehicleType} onChange={filter.setFilterVehicleType} placeholder="Vehicle type" label={item => Lookup.getVehicleType[item]}>
          <Dropdown.Column>{item => Lookup.getVehicleType[item]}</Dropdown.Column>
        </Dropdown>              
      </Form.Uncontrolled>

      <Form.Uncontrolled label="Driver age">
        <Dropdown data={Lookup.keys(Lookup.getAges)} fluid clearable value={filter.filterDriverAge} onChange={filter.setFilterDriverAge} placeholder="Driver age" label={item => Lookup.getAges[item]}>
          <Dropdown.Column>{item => Lookup.getAges[item]}</Dropdown.Column>
        </Dropdown>              
      </Form.Uncontrolled>

      <Form.Uncontrolled label="Breath test">
        <Checkbox checked={filter.filterBreathTestPositive} onChange={filter.setFilterBreathTestPositive} label="Breath test positive/refused"/>
      </Form.Uncontrolled>

      <Divider>Casualties</Divider>

      <Form.Uncontrolled label="Casualty class">
        <Dropdown data={Lookup.keys(Lookup.getCasualtyClass)} fluid clearable value={filter.filterCasualtyClass} onChange={filter.setFilterCasualtyClass} placeholder="Casualty class" label={item => Lookup.getCasualtyClass[item]}>
          <Dropdown.Column>{item => Lookup.getCasualtyClass[item]}</Dropdown.Column>
        </Dropdown>              
      </Form.Uncontrolled>      

      <Form.Uncontrolled label="Casualty age">
        <Dropdown data={Lookup.keys(Lookup.getAges)} fluid clearable value={filter.filterCasualtyAge} onChange={filter.setFilterCasualtyAge} placeholder="Casualty age" label={item => Lookup.getAges[item]}>
          <Dropdown.Column>{item => Lookup.getAges[item]}</Dropdown.Column>
        </Dropdown>              
      </Form.Uncontrolled>      

      <Form.Uncontrolled label="Casualty severity">
        <Dropdown data={Lookup.keys(Lookup.getCasualtySeverity)} fluid clearable value={filter.filterCasualtySeverity} onChange={filter.setFilterCasualtySeverity} placeholder="Casualty severity" label={item => Lookup.getCasualtySeverity[item]}>
          <Dropdown.Column>{item => Lookup.getCasualtySeverity[item]}</Dropdown.Column>
        </Dropdown>              
      </Form.Uncontrolled>         

      <TableButton onClick={handleTable}/>
    </div>
  )
}

const Header = styled.div`
  font-size: 12px;
  color: #666;
  font-weight: 500;
  padding-bottom: 4px;
`

const FilterPanel = styled(FilterPanelBase)`
  box-shadow: -1px 0px 3px rgba(0,0,0,0.8);
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
`

export { FilterPanel }
