import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable, Form, Input } from '@independent-software/typeui/controls';
import { TDir } from '@independent-software/typeui/controls/Types';

import { useAuth } from '../../auth/useAuth';
import { ProfileApi } from '../../api/profile/ProfileApi';
import { IProfile } from '../../api/profile/IProfile';
import { UI } from '../../components/UI/UI';
import { Highlight } from '../../controls/Highlight';

interface IProps {
  countCallback?: (count: number) => void;
}

const ProfileList = (props: IProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<string>("name");
  const [dir, setDir] = React.useState<TDir>('asc');
  const [profiles, setProfiles] = React.useState<IProfile[]>([]);
  const [q, setQ] = React.useState<string>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => handleFetch(0, 33, true), [order, dir, q]);

  const handleChangeQ = (value: string) => {
    setQ(value);
  }

  const handleOrder = (newOrder: string, newDir: TDir) => {
    setOrder(newOrder);
    setDir(newDir);
    if(order === newOrder) setDir(dir === 'asc' ? 'desc' : 'asc');
  }

  const handleFetch = (offset: number, count: number, erase?: boolean) => {
    setLoading(true);
    ProfileApi.list(order, dir, offset, count, q, auth.access_token)
    .then(res => {
      // Notify subscriber that count has changed:
      if(props.countCallback) props.countCallback(res.count);      
      let newItems = profiles.slice();
      // Erase all items if count is different, or the filter changed:
      if(erase || res.count != profiles.length) {
        newItems = Array(res.count).fill(null);
      }
      // Copy fetch results into array:
      res.data.forEach((u, index) => {
        newItems[offset + index] = u;
      });
      setProfiles(newItems);
    })
    .finally(() => setLoading(false));
  }

  const handleSelectProfile = (profile: IProfile) => {
    navigate(`/profile/${profile.uuid}`);
  }

  return (
    <>
      <UI.FilterBox>
        <Form.Uncontrolled hint="Type to filter profiles list">
          <Input clearable value={q} type="text" placeholder="Search profiles..." onChange={handleChangeQ}/>
        </Form.Uncontrolled>
      </UI.FilterBox>
      <DataTable loading={loading} data={profiles} order={order} dir={dir} onOrder={handleOrder} onFetch={handleFetch} onClick={handleSelectProfile}>
        <DataTable.Column weight={2} label="Name" order="name" dir="asc">
          {(item:IProfile) => <Highlight value={item.name} q={q}/>}
        </DataTable.Column>
        <DataTable.Column weight={1} label="Users" order="users_count" dir="asc">
          {(item:IProfile) => item.users_count}
        </DataTable.Column>        
      </DataTable>  
    </>
  )
}

export { ProfileList }
