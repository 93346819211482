import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Message } from '@independent-software/typeui/controls';

import { useAuth } from '../../auth/useAuth';
import { UI } from '../../components/UI/UI';
import { IProfile } from '../../api/profile/IProfile';
import { ProfileApi } from '../../api/profile/ProfileApi';
import { ProfileForm } from './ProfileForm';

type TStep = 'ready' | 'loading' | 'loadError' | 'saving' | 'saveError' | 'error';

const EditProfile = () => {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const currentProfile = React.useRef(null);
  const [profile, setProfile] = React.useState<IProfile>(null);
  const [step, setStep] = React.useState<TStep>("loading");
  const [dirty, setDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState(null)

  const loadProfile = () => {
    setError(null);
    setStep('loading');
    ProfileApi.get(params.uuid, auth.access_token)
    .then(res => {
      currentProfile.current = res;
      setProfile(res);
      setStep('ready');
    })
    .catch((err) => {
      setStep('loadError');
      setError(err);
    });
  }

  React.useEffect(() => {
    loadProfile();
  }, []);

  const handleChange = (newProfile: IProfile, forceupdate: boolean) => {
    currentProfile.current = Object.assign(currentProfile.current, newProfile);
  }

  const handleValidate = (valid: boolean) => {
    setIsValid(valid);
  }  

  const handleSubmit = () => {
    setProfile(currentProfile.current);
    setDirty(true);
    if(!isValid) return;
    setError(null);
    setStep('saving');

    ProfileApi.update(currentProfile.current, auth.access_token)
    .then(() => {
      ToastService.toast("Profile updated successfully.");
      navigate(`/profile/${profile.uuid}`);
    })
    .catch(error => {
      setStep('saveError');
      setError(error);
    });
  }

  return (
    <UI.PaddedScreen>
      <UI.Header>Edit Profile</UI.Header>
      {(step == 'loading' || step == 'saving') && <Loader></Loader>}

      {/* Form */}
      {step != 'loading' && step != 'loadError' && step != 'error' && <>
        <UI.Content>
          <ProfileForm dirty={dirty} data={profile} onChange={handleChange} onValidate={handleValidate}/>
        </UI.Content>
        <UI.Footer>
          <Button secondary onClick={() => navigate(`/profile/${params.uuid}`)}>Cancel</Button>
          <Button primary disabled={!isValid} onClick={handleSubmit}><Icon name="save"/> Save profile</Button>
        </UI.Footer>
      </>}

      {/* Fatal error */}
      {step == 'error' && <>
        <UI.Content>
          <Message type="error">The requested data could not be found.</Message>
        </UI.Content>
        <UI.Footer>
          <Button secondary onClick={() => navigate(`/profile/${params.uuid}`)}>Cancel</Button>
        </UI.Footer>
      </>}

      {/* Load error, retryable */}
      <Dialog.Xhr open={step == 'loadError'} error={error} onClose={() => setStep('error')} onRetry={loadProfile}/>
      
      {/* Save error, retryable */}
      <Dialog.Xhr open={step == 'saveError'} error={error} onClose={() => setStep('ready')} onRetry={handleSubmit}/>

    </UI.PaddedScreen>
  );
}

export { EditProfile }
