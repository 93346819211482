import { Dropdown } from '@independent-software/typeui/controls';
import * as React from 'react';

interface IFactorDefinition {
  category: number;
  name: string;
}

class Lookup {
  static keys = (arr: any[]): number[] => {
    return Array.from(arr.keys());
  }

  static getDropdown = (arr: string[], placeholder: string): React.ReactNode => {
    return (
      <Dropdown maxItems={5} data={Lookup.keys(arr)} fluid placeholder={placeholder} label={item => arr[item]}>
        <Dropdown.Column>{item => arr[item]}</Dropdown.Column>
      </Dropdown>      
    )
  }  

  static getDivisions = [
    "1", "2", "3", "4a", "4b", "4c", "5", "6", "7", "8", "9", "10", "11", "12"
  ]

  static getRoadTypes = [
    "Roundabout", 
    "One way street", 
    "Dual carriageway", 
    "Single carriageway", 
    "Slip road", 
    "Unknown"
  ]

  public static getRoadLanes = [
    "1",
    "2",
    "3",
    "4"
  ];

  static getJunctionTypes = [
    "Not at/within 20m of junction",
    "Roundabout",
    "Mini roundabout",
    "T or staggered junction",
    "Slip road",
    "Crossroads",
    "Junction more than four arms (not RAB)",
    "Using private drive/entrance",
    "Other junction"
  ]

  static getJunctionControl = [
    "N/A",
    "Authorised person",
    "Automatic traffic signal",
    "Stop sign",
    "Give way or uncontrolled"
  ]

  static getPedestrianHumanControl = [
    "None within 50m",
    "Control by school crossing patrol",
    "Control by other authorised person"
  ]

  static getPedestrianPhysicalFacilities = [
    "No physical crossing facility within 50m",
    "Zebra crossing",
    "Standalone signalised pedestrian crossing",
    "Pedestrian phase at traffic signal junction",
    "Traffic signal junction with no pedestrian phase",
    "Footbridge or subway",
    "Central refuge - no other controls"
  ]

  static getWeather = [
    "Fine without high winds",
    "Raining without high winds",
    "Fine with high winds",
    "Raining with high winds",
    "Fog or mist - if hazard",
    "Other",
    "Unknown"
  ]

  static getRoadSurface = [
    "Dry",
    "Wet/Damp",
    "Flood (surface water over 3cm deep)",
    "Poor friction due to stand/stone in carriageway"
  ]

  static getLight = [
    "Daylight",
    "Darkness: street lights present and lit",
    "Darkness: street lights present but unlit",
    "Darkness: no street lighting",
    "Darkness: street lighting unknown"
  ]

  static getSpecialConditions = [
    "Auto traffic signal out",
    "Auto traffic signal partically defective",
    "Permanent road signing or marking defective/obscured",
    "Roadworks",
    "Road surface defective",
    "Oil or diesel",
    "Mud",
    "Smoke"
  ]

  static getCarriagewayHazards = [
    "None",
    "Dislodged vehicle load in carriageway",
    "Other object in carriageway",
    "Involvement with previous accident",
    "Pedestrian in carriageway - not injured",
    "Any animal in carriageway (except ridden horse)"
  ]

  static getYesNo = [
    "Yes",
    "No"
  ]

  static getRightHand = [
    "Yes",
    "No",
    "Other"
  ]

  static getVehicleType = [
    /* 00 */ "Car",
    /* 01 */ "Taxi",
    /* 02 */ "Hire/Rental Car",
    /* 03 */ "Van - Goods vehicle under 3.5t mgw",
    /* 04 */ "Goods vehicle between 3.5t and 7.5t mgw",
    /* 05 */ "Goods vehicle over 7.5t mgw",
    /* 06 */ "Goods vehicle - unknown weight",
    /* 07 */ "Motorcycle 50cc and under",
    /* 08 */ "Motorcycle between 50cc and 125cc",
    /* 09 */ "Motorcycle between 125cc and 500cc",
    /* 10 */ "Motorcycle over 500cc",
    /* 11 */ "Motorcycle - cc unknown",
    /* 12 */ "Electric motorcycle",
    /* 13 */ "Pedal cycle",
    /* 14 */ "Bus or coach (17+ passenger seats)",
    /* 15 */ "Minibus (8-16 passenger seats)",
    /* 16 */ "Agricultural vehicle (include diggers etc.)",
    /* 17 */ "Ridden horse",
    /* 18 */ "Mobility scooter",
    /* 19 */ "Tram/Light rail",
    /* 20 */ "Other"
  ]

  static getTowingArticulation = [
    "No tow or articulation",
    "Articulated vehicle",
    "Double or multiple trailer",
    "Caravan",
    "Single trailer",
    "Other tow"
  ]

  static getLicence = [
    "Licenced driver", 
    "Learner / Provisional", 
    "Learner / Provisional with qualified driver present", 
    "Unlicenced", 
    "Unknown"     
  ]

  static getLiability = [
    "0%",
    "25%",
    "50%",
    "75%",
    "100%"
  ]

  static getBreathTest = [
    "N/A",
    "Positive",
    "Negative",
    "Not requested",
    "Refused to provide",
    "Driver not contacted at time of accident",
    "Not provided (medical reasons)"
  ]

  static getHitAndRun = [
    "Not hit and run",
    "Hit and run",
    "Non-stop vehicle, not hit"
  ]

  static getSex = [
    "Male",
    "Female",
    "Not known"
  ]

  static getEthnicity = [
    "East Indian",
    "African",
    "Amerindian",
    "Chinese",
    "Portuguese",
    "European",
    "Mixed",
    "Other"
  ]

  static getNationality = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Ni-Vanuatu",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean"
  ]

  static getCarriagewayLocation = [
    "On main carriageway not in restricted lane",
    "Tram/Light rail track",
    "Bus lane",
    "Busway (inc. guided busway)",
    "Cycle lane (on main carriageway)",
    "Cycleway or shared use footway (not part of main carriageway)",
    "On lay-by/hard shoulder",
    "Entering lay-by/hard shoulder",
    "Leaving lay-by/hard shoulder",
    "Footway (pavement)"
  ]

  static getJunctionLocation = [
    "Not at/within 20m of junction",
    "Approaching junction or waiting/parked at junction approach",
    "Cleared junction or waiting/parked at junction exit",
    "Leaving roundabout",
    "Entering roundabout",
    "Leaving main road",
    "Entering main road",
    "Entering from slip road",
    "Mid junction - on roundabout or on main road"
  ]

  static getManoeuvres = [
    "Reversing",
    "Parked",
    "Waiting to go ahead but held up",
    "Slowing or stopping",
    "Moving off",
    "U turn",
    "Turning left",
    "Waiting to turn left",
    "Turning right",
    "Waiting to turn right",
    "Changing lane to left",
    "Changing lane to right",
    "Overtaking moving vehicle on its outside",
    "Overtaking moving vehicle on its inside",
    "Going ahead left hand bend",
    "Going ahead right hand bend",
    "Going ahead other"
  ]

  static getSkiddingOverturning = [
    "No skidding, jack-knifing or overturning",
    "Skidded",
    "Skidded and overturned",
    "Jack-knifed",
    "Jack-knifed and overturned",
    "Overturned"
  ]

  static getHitObjectInCarriageway = [
    "None", 
    "Previous accident",
    "Roadworks",
    "Parked vehicle",
    "Bridge - roof",
    "Bridge - side",
    "Bollard/refuge",
    "Open door of vehicle",
    "Central island of roundabout",
    "Kerb",
    "Any animal (except ridden horse)",
    "Other object"
  ]

  static getVehicleLeavingCarriageway = [
    "Did not leave carriageway",
    "Left carriageway nearside",
    "Left carriageway nearside and rebounded",
    "Left carriageway straight ahead at junction",
    "Left carriageway offside onto central reservation",
    "Left carriageway offside onto central reserve and rebounded",
    "Left carriageway offside and crossed central reservation",
    "Left carriageway offside",
    "Left carriageway offside and rebounded"
  ]

  static getHitObjectOffCarriageway = [
    "None",
    "Road sign / Traffic signal",
    "Lamp post",
    "Telegraph pole / Electricity pole",
    "Tree",
    "Bus stop / Bus shelter",
    "Central crash barrier",
    "Nearside or offside crash barrier",
    "Submerged in water (completely)",
    "Entered ditch",
    "Wall or fence",
    "Other permanent object"
  ]

  static getImpact = [
    "Did not impact",
    "Front",
    "Back",
    "Offside",
    "Nearside"
  ]

  static getJourneyPurpose = [
    "Journey as part of work",
    "Commuting to/from work",
    "Taking school pupil to/from school",
    "Pupil riding to/from school",
    "Leisure",
    "Other",
    "Not known"
  ]

  static getCasualtyClass = [
    "Driver/Rider",
    "Vehicle/Pillion Passenger",
    "Pedestrian"
  ]

  static getCasualtySeverity = [
    "Fatal",
    "Serious",
    "Minor"
  ]

  static getCycleHelmet = [
    "Not a cyclist",
    "Yes",
    "No",
    "Not known"
  ]

  static getCarPassenger = [
    "Not a car passenger",
    "Front seat passenger",
    "Back seat passenger"
  ]  

  static getBusPassenger = [
    "Not a bus/coach passenger",
    "Boarding",
    "Alighting",
    "Standing passenger",
    "Seated passenger"
  ]

  static getSeatBelt = [
    "N/A",
    "Worn and independently confirmed",
    "Worn but not independently confirmed",
    "Not worn",
    "Unknown"
  ]

  static getPedestrianLocation = [
    "In carriageway, crossing on pedestrian crossing facility",
    "In carriageway, crossing within stop line at crossing approach",
    "In carriageway, crossing within stop line at crossing exit",
    "In carriageway, crossing elsewhere within 50m of pedestrian crossing",
    "In carriageway, crossing elsewhere",
    "On footway or verge",
    "On refuge, central island or central reservation",
    "In centre of carriageway, not on refuge, island or central reservation",
    "In carriageway, not crossing",
    "Unknown or other"
  ]

  static getPedestrianDirection = [
    "Standing still",
    "Northbound",
    "Northeast bound",
    "Eastbound",
    "Southeast bound",
    "Southbound",
    "Southwest bound",
    "Westbound",
    "Northwest bound",
    "Unknown"
  ]

  static getPedestrianRoadMaintenanceWorker = [
    "No / Not applicable",
    "Yes",
    "Not known"
  ]

  static getPedestrianMovement = [
    "Crossing from driver's nearside",
    "Crossing from driver's nearside - masked by parked or stationary vehicle",
    "Crossing from driver's offside",
    "Crossing from driver's offside - masked by parked or stationary vehicle",
    "In carriageway, stationary - not crossing (standing or playing)",
    "In carriageway, stationary - not crossing (standing or playing) - masked by parked or stationary vehicle",
    "Walking along in carriageway - facing traffic",
    "Walking along in carriageway - back to traffic",
    "Unknown or other"
  ]

  static getCategories = [
    "Road Environment Contributed",
    "Vehicle Defects",
    "Injudicious Action",
    "Driver/Rider Error or Reaction",
    "Impairment or Distraction",
    "Behaviour or Inexperience",
    "Vision Affected by",
    "Pedestrian Only (Casualy or Uninjured)",
    "Special Codes"
  ]

  static getDirection = [
    "Parked",
    "North",
    "Northeast",
    "East",
    "Southeast",
    "South",
    "Southwest",
    "West",
    "Northwest"
  ]

  static getAges = [
    "0-15",
    "16-29",
    "30-45",
    "46-59",
    "60+"
  ]

  static getClothing = [
    "Not applicable",
    "Light",
    "Dark"
  ]

  static getFactors: IFactorDefinition[] = [
    { category: 0, name: "Slippery road (due to weather)" },
    { category: 0, name: "Deposit on road (e.g. oil, mud, chippings)" },
    { category: 0, name: "Poor or defective road surface" },
    { category: 0, name: "Sunken, raised or slippery inspection cover" },
    { category: 0, name: "Road layout (e.g. bend, hill, narrow carriageway" },
    { category: 0, name: "Temporary road layout (e.g. contraflow)" },
    { category: 0, name: "Animal or object in carriageway" },
    { category: 0, name: "Inadequate or masked signs or road markings" },
    { category: 0, name: "Defective traffic signals" },
    { category: 0, name: "Traffic calming (e.g. speed cushions, road humps, chicanes)" },
    { category: 1, name: "Tyres illegal, defective or under-inflated" },
    { category: 1, name: "Defective lights or indicators" },
    { category: 1, name: "Defective brakes" },
    { category: 1, name: "Defective steering or suspension" },
    { category: 1, name: "Defective or missing mirrors" },
    { category: 1, name: "Overloaded or poorly loaded vehicle or trailer" },
    { category: 2, name: "Following too close" },
    { category: 2, name: "Exceeding speed limit" },
    { category: 2, name: "Disobeyed Give Way or Stop sign or markings" },
    { category: 2, name: "Disobeyed automatic traffic signal" },
    { category: 2, name: "Travelling too fast for conditions" },
    { category: 2, name: "Cyclist entering road from pavement" },
    { category: 2, name: "Illegal turn or direction of travel" },
    { category: 2, name: "Disobeyed pedestrian crossing facility" },
    { category: 2, name: "Vehicle travelling along pavement" },
    { category: 3, name: "Failed to look properly" },
    { category: 3, name: "Failed to judge other person's path or speed" },
    { category: 3, name: "Poor turn or manoeuvre" },
    { category: 3, name: "Sudden braking" },
    { category: 3, name: "Swerved" },
    { category: 3, name: "Junction overshoot" },
    { category: 3, name: "Junction restart (moving off at junction)" },
    { category: 3, name: "Failed to signal or misleading signal" },
    { category: 3, name: "Too close to cyclist, horse or pedestrian" },
    { category: 3, name: "Loss of control" },
    { category: 4, name: "Impaired by alcohol" },
    { category: 4, name: "Impaired by drugs (illicit or medicinal)" },
    { category: 4, name: "Driver using mobile phone" },
    { category: 4, name: "Fatigue" },
    { category: 4, name: "Distraction in vehicle" },
    { category: 4, name: "Distraction outside vehicle" },
    { category: 4, name: "Illness or disability, mental or physical" },
    { category: 4, name: "Uncorrected, defective eyesight" },
    { category: 4, name: "Rider wearing dark clothing" },
    { category: 4, name: "Not displaying lights at night or in poor visibility" },
    { category: 5, name: "Careless, reckless or in a hurry" },
    { category: 5, name: "Learner or inexperienced driver/rider" },
    { category: 5, name: "Aggressive driving" },
    { category: 5, name: "Nervous, uncertain or panic" },
    { category: 5, name: "Unfamiliar with model of vehicle" },
    { category: 5, name: "Inexperience of driving on the right" },
    { category: 5, name: "Driving too slow for conditions or slow vehicle (e.g. tractor)" },
    { category: 6, name: "Stationary or parked vehicles(s)" },
    { category: 6, name: "Road layout (e.g. bend, winding road, hill crest)" },
    { category: 6, name: "Dazzling sun" },
    { category: 6, name: "Rain, sleet, or fog" },
    { category: 6, name: "Spray from other vehicles" },
    { category: 6, name: "Dazzling headlights" },
    { category: 6, name: "Vehicle blind spot" },
    { category: 6, name: "Vegetation" },
    { category: 6, name: "Buildings, road signs, street furniture" },
    { category: 6, name: "Visor or windscreen dirty, scratched or frosted etc." },
    { category: 7, name: "Failed to look properly" },
    { category: 7, name: "Careless, reckless or in a hurry" },
    { category: 7, name: "Failed to judge vehicle's path or speed" },
    { category: 7, name: "Crossing road masked by stationary or parked vehicle" },
    { category: 7, name: "Impaired by alcohol" },
    { category: 7, name: "Impaired by drugs (illicit or medicinal)" },
    { category: 7, name: "Dangerous action in carriageway (e.g. playing)" },
    { category: 7, name: "Wrong use pedestrian crossing facility" },
    { category: 7, name: "Pedestrian wearing dark clothing at night" },
    { category: 7, name: "Disability or illness, mental or physical" },
    { category: 8, name: "Stolen vehicle" },
    { category: 8, name: "Vehicle in course of crime" },
    { category: 8, name: "Emergency vehicle on a call" },
    { category: 8, name: "Vehicle door opened or closed negligently" }
  ]
}

export { Lookup, IFactorDefinition }
