/**
 * Smallest cluster radius in px.
 */
export const CLUSTER_SIZE      = 12;
/**
 * Cluster growth in px for each size level.
 */
export const CLUSTER_SIZE_STEP =  4; 

/**
 * Zoom level at which clusters are gone and markers are drawn.
 */
export const CLUSTER_CUTOFF = 10;

/** Zoom level at which clusters start to become visible. */
export const CLUSTER_OPACITY_START = 2;
/** Zoom level at which clusters are fully visible. */
export const CLUSTER_OPACITY_END   = 6;

/** Cluster color */
export const CLUSTER_FILL_COLOR = "#ffffff";
export const CLUSTER_STROKE_COLOR = "#ffffff";

/** Cluster text color */
export const CLUSTER_TEXT_COLOR = "#333333";

/** Marker color */
export const MARKER_FILL_COLOR = "#6BC1FD";
export const MARKER_STROKE_COLOR = "#333333";
export const MARKER_HOVER_COLOR = "#ffffff";
