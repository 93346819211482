import styled from "styled-components";

const Content = styled.div`
  flex: 1;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`

export { Content }
