import * as React from 'react';
import styled from 'styled-components';

import { MyMap } from './MyMap';
import { AccidentList } from './AccidentList';
import { useFilter } from '../../contexts/useFilter';
import { TableHolder } from './TableHolder';
import { MapHolder } from './MapHolder';
import { FilterPanel } from './FilterPanel';

interface IProps {
  className?: string;
}

const HomeBase = (props: IProps) => {
  const filter = useFilter();

  return (
    <div className={props.className}>
      <WorkArea>
        <TableHolder tableState={filter.tableState}>
          <AccidentList/>
        </TableHolder>
        <MapHolder>
          <MyMap/>
        </MapHolder>
      </WorkArea>
      <FilterPanelHolder>
        <FilterPanel/>
      </FilterPanelHolder>
    </div>
  )
}

const WorkArea = styled.div`
  position: relative;
  flex: 1;
  z-index: 0;
`

const FilterPanelHolder = styled.div`
  width: 334px;
  z-index: 1;
  background: #fff;
`

const Home = styled(HomeBase)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`

export { Home }
