import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
}

const MapStyleHintBase = (props: IProps) => {
  return (
    <div className={props.className}>
      Toggle map style
    </div>
  );
}

const MapStyleHint = styled(MapStyleHintBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export { MapStyleHint }
