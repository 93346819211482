import * as React from 'react';

import { Input } from '@independent-software/typeui/controls/Input';
import { Form } from '@independent-software/typeui/controls/Form';
import { Flex, Message } from '@independent-software/typeui/controls';
import { UI } from '../../../components/UI/UI';
import { Lookup } from '../Lookup';
import { ICasualty } from '../../../api/accident/ICasualty';
import { Rose } from '../controls/Rose';

interface IProps {
  /** Initial form props.data. */
  index: number;
  data: ICasualty;
}

const FormCasualty = (props: IProps) => {

  return (
    <Flex.Columns count={2}>

      <div>
        <UI.Block title="Casualty Information">
          <Form.Field
            forceupdate
            required={{ message: "Casualty class is required."}}
            width={1} label="Casualty Class"
            name={`casualties[${props.index}].class`}
            value={props.data.class}
            control={Lookup.getDropdown(Lookup.getCasualtyClass, "Casualty Class")}
            hint="Please select the casualty class."/>   

          {(props.data.class == 0 || props.data.class == 1) && 
          <Form.Field
            forceupdate
            isInt={{ message: "Vehicle number must be a whole number."}}
            min={{ value: 1, message: "Vehicle number must be at least 1."}}
            max={{ value: 99, message: "Vehicle number may not be greater than 99."}}
            width={1} label="Vehicle number"
            name={`casualties[${props.index}].vehicle`}
            value={props.data.vehicle}
            control={(<Input type="text" placeholder="Vehicle number" fluid/>)}
            hint="Please select the vehicle the casualty was in."/>               
          }

          <Form.Field
            maxLength={{ length: 255, message: "Name may not be longer than 255 characters."}}
            width={1} label="Casualty Name"
            name={`casualties[${props.index}].name`}
            value={props.data.name}
            control={(<Input type="text" placeholder="Casualty Name" fluid/>)}
            hint={<>Please enter the casualty's name.</>}/>

          <Form.Field
            maxLength={{ length: 255, message: "Address may not be longer than 255 characters."}}
            width={1} label="Casualty Address"
            name={`casualties[${props.index}].address`}
            value={props.data.name}
            control={(<Input type="text" placeholder="Casualty Address" fluid/>)}
            hint={<>Please enter the casualty's address.</>}/>            
        
          <Form.Field
            // required={{ message: "Sex of casualty is required."}}
            width={1} label="Sex of Casualty"
            name={`casualties[${props.index}].sex`}
            value={props.data.sex}
            control={Lookup.getDropdown(Lookup.getSex, "Sex of Casualty")}
            hint="Please select the sex of the casualty."/>        

          <Form.Field
            // required={{ message: "Age of casualty is required."}}
            isInt={{ message: "Age must be a whole number."}}
            min={{ value: 0, message: "Age must be at least 0."}}
            max={{ value: 150, message: "Age may not be greater than 150."}}
            width={1} label="Age of Casualty"
            name={`casualties[${props.index}].age`}
            value={props.data.age}
            control={(<Input type="text" placeholder="Age of Casualty" fluid/>)}
            hint={<>Please enter the casualty's age (estimate if necessary). For children less than a year enter <code>0</code>.</>}/>            

          <Form.Field
            // required={{ message: "Severity of casualty is required."}}
            width={1} label="Severity of Casualty"
            name={`casualties[${props.index}].severity`}
            value={props.data.severity}
            control={Lookup.getDropdown(Lookup.getCasualtySeverity, "Severity of Casualty")}
            hint="Please select the severity of the casualty."/>   

        </UI.Block>

        <UI.Block title="Situational Information">

          <Form.Field
            // required={{ message: "Cycle helmet is required."}}
            width={1} label="Cycle Helmet Worn"
            name={`casualties[${props.index}].helmet`}
            value={props.data.helmet}
            control={Lookup.getDropdown(Lookup.getCycleHelmet, "Cycle Helmet Worn")}
            hint="Please select whether the casualty was wearing a cycle helmet."/>              

          <Form.Field
            width={1} label="Clothing"
            name={`casualties[${props.index}].clothing`}
            value={props.data.clothing}
            control={Lookup.getDropdown(Lookup.getClothing, "Type of Clothing Worn")}
            hint="Please select the color of clothes worn by the casualty.  This applies only to casualties that are exposed to the exterior directly, e.g. pedestrians, motorcycle riders / passengers, horse riders, cyclists."/>


          <Form.Field
            // required={{ message: "Car passenger status is required."}}
            width={1} label="Car Passenger?"
            name={`casualties[${props.index}].car_passenger`}
            value={props.data.car_passenger}
            control={Lookup.getDropdown(Lookup.getCarPassenger, "Car Passenger?")}
            hint="Please indicate whether the casualty was a car passenger."/>                          

          <Form.Field
            // required={{ message: "Bus/coach passenger status is required."}}
            width={1} label="Bus or Coach Passenger?"
            name={`casualties[${props.index}].bus_passenger`}
            value={props.data.bus_passenger}
            control={Lookup.getDropdown(Lookup.getBusPassenger, "Bus or Coach Passenger?")}
            hint="Please indicate whether the casualty was a bus or coach passenger."/>                          

          <Form.Field
            // required={{ message: "Seat belt status is required."}}
            width={1} label="Seat Belt in Use"
            name={`casualties[${props.index}].seat_belt`}
            value={props.data.seat_belt}
            control={Lookup.getDropdown(Lookup.getSeatBelt, "Selt Belt in Use")}
            hint="Please indicate whether the casualty was using a seat belt."/>         

        </UI.Block>

      </div>
      <div>

        {props.data.class == 2 && <UI.Block title="Pedestrian Casualty">

          <Message type="info">
            These questions are only applicable to pedestrian casualties.
          </Message>

          <Form.Field
            width={1} label="Pedestrian liability"
            name={`casualties[${props.index}].pedestrian_liability`}
            value={props.data.pedestrian_liability}
            control={Lookup.getDropdown(Lookup.getLiability, "Pedestrian Liability")}
            hint="This pedestrian was responsible for the crash by what percentage?"/>

          <Form.Field
            width={1} label="Pedestrian Location"
            name={`casualties[${props.index}].pedestrian_location`}
            value={props.data.pedestrian_location}
            control={Lookup.getDropdown(Lookup.getPedestrianLocation, "Pedestrian Location")}
            hint="Please select the location of the pedestrian casualty."/>

          <Form.Field
            width={1} label="Pedestrian Direction"
            name={`casualties[${props.index}].pedestrian_direction`}
            value={props.data.pedestrian_direction}
            control={<Rose/>}
            hint="Please select the direction of movement of the pedestrian casualty."/>      

          <Form.Field
            width={1} label="Pedestrian Movement"
            name={`casualties[${props.index}].pedestrian_movement`}
            value={props.data.pedestrian_movement}
            control={Lookup.getDropdown(Lookup.getPedestrianMovement, "Pedestrian Movement")}
            hint="Please select the movement of the pedestrian casualty."/>      

          <Form.Field
            width={1} label="Pedestrian Road Maintenance Worker"
            name={`casualties[${props.index}].pedestrian_worker`}
            value={props.data.pedestrian_worker}
            control={Lookup.getDropdown(Lookup.getPedestrianRoadMaintenanceWorker, "Pedestrian Road Maintenance Worker")}
            hint="Please indicate whether the casualty was a pedestrian road maintenance worker."/>      

        </UI.Block>}

      </div>

    </Flex.Columns>
  )
}

export { FormCasualty }
