import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

import { Icon } from '@independent-software/typeui/controls/Icon'
import { Panel } from '@independent-software/typeui/controls/Panel'
import { Divider } from '@independent-software/typeui/controls/Divider'

import { IStampedModel } from '../../api/StampedModel';
import { StampRow } from './StampRow';

interface IProps {
  /** @ignore */
  className?: string;
  model: IStampedModel;
}

const TimestampBase = (props: IProps) => {
  // Panel currently open?
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div className={props.className}>
      <Icon color="#888" size="large" name="heart-beat" onClick={handleClick}/>
      <Panel open={open} onClose={handleClose}>
        <StampRow label="Created" user={props.model.created_by} time={props.model.created_at}/>
        {props.model.updated_by && <React.Fragment>
          <Divider fitted/>
          <StampRow label="Updated" user={props.model.updated_by} time={props.model.updated_at}/>
        </React.Fragment>}
      </Panel>
    </div>
  );
}

const Timestamp = styled(TimestampBase)`
  position: relative;

  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
`

export { Timestamp }
