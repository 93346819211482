import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Message } from '@independent-software/typeui/controls';

import { useAuth } from '../../auth/useAuth';
import { useFilter } from '../../contexts/useFilter';
import { AccidentForm } from './forms/AccidentForm';
import { UI } from '../../components/UI/UI';
import { AccidentApi } from '../../api/accident/AccidentApi';
import { IAccident } from '../../api/accident/IAccident';
import { IAccidentProxy } from '../../api/accident/IAccidentProxy';

type TStep = 'ready' | 'loading' | 'loadError' | 'saving' | 'saveError' | 'error';

const EditAccident = () => {
  const auth = useAuth();
  const params = useParams();
  const filter = useFilter();
  const navigate = useNavigate();
  const currentAccident = React.useRef(null);
  const [accident, setAccident] = React.useState<IAccident>(null);
  const [step, setStep] = React.useState<TStep>("loading");
  const [dirty, setDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState(null)

  const loadAccident = () => {
    setError(null);
    setStep('loading');
    AccidentApi.get(params.uuid, auth.access_token)
    .then(res => {
      currentAccident.current = res;
      setAccident(res);
      setStep('ready');
    })
    .catch((err) => {
      setStep('loadError');
      setError(err);
    });
  }

  React.useEffect(() => {
    loadAccident();
  }, []);

  const handleChange = (newAccident: IAccident, forceupdate: boolean) => {
    currentAccident.current = Object.assign(currentAccident.current, newAccident);
    if(forceupdate) { 
      setAccident({...newAccident});
    }        
  }

  const handleValidate = (valid: boolean) => {
    setIsValid(valid);
  }  

  const handleSubmit = () => {
    setAccident(currentAccident.current);
    setDirty(true);
    if(!isValid) return;
    setError(null);
    setStep('saving');

    AccidentApi.update(currentAccident.current, auth.access_token)
    .then(() => {
      ToastService.toast("Accident updated successfully.");
      const proxy: IAccidentProxy = {
        uuid: accident.uuid,
        datetime: accident.date + ' ' + accident.time,
        latitude: accident.latitude,
        longitude: accident.longitude,
        general_town: accident.general_town,
        vehicles_count: accident.vehicles.length,
        casualties_count: accident.casualties.length    
      }      
      filter.updateAccident(proxy);
      navigate(`/accident/${accident.uuid}`);
    })
    .catch(error => {
      setStep('saveError');
      setError(error);
    });
  }

  return (
    <UI.PaddedScreen>
      <UI.Header>Edit Accident</UI.Header>
      {(step == 'loading' || step == 'saving') && <Loader></Loader>}

      {/* Form */}
      {step != 'loading' && step != 'loadError' && step != 'error' && <>
        <UI.Content>
          <AccidentForm dirty={dirty} data={accident} onChange={handleChange} onValidate={handleValidate}/>
        </UI.Content>
        <UI.Footer>
          <Button secondary onClick={() => navigate(`/accident/${params.uuid}`)}>Cancel</Button>
          <Button primary disabled={!isValid} onClick={handleSubmit}><Icon name="save"/> Save accident</Button>
        </UI.Footer>
      </>}

      {/* Fatal error */}
      {step == 'error' && <>
        <UI.Content>
          <Message type="error">The requested data could not be found.</Message>
        </UI.Content>
        <UI.Footer>
          <Button secondary onClick={() => navigate(`/accident/${params.uuid}`)}>Cancel</Button>
        </UI.Footer>
      </>}

      {/* Load error, retryable */}
      <Dialog.Xhr open={step == 'loadError'} error={error} onClose={() => setStep('error')} onRetry={loadAccident}/>
      
      {/* Save error, retryable */}
      <Dialog.Xhr open={step == 'saveError'} error={error} onClose={() => setStep('ready')} onRetry={handleSubmit}/>

    </UI.PaddedScreen>
  );
}

export { EditAccident }
