import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@independent-software/typeui/controls';

import { useAuth } from '../../auth/useAuth';
import { UI } from '../../components/UI/UI';
import { UserList } from './UserList';

const Users = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <UI.PaddedScreen>
      <UI.Header>Users</UI.Header>
      <UserList/>
      <UI.Footer>
        <UI.FooterSection></UI.FooterSection>
        <UI.FooterSection>{auth.rights.includes('can_edit_users') && <Button primary onClick={() => navigate("/user/add")}><Icon name="plus"/> Add user</Button>}</UI.FooterSection>
      </UI.Footer>
    </UI.PaddedScreen>
  )
}


export { Users }
