import { saveAs } from 'file-saver';
import axios, { AxiosResponse } from 'axios';
import { App } from "../../App";
import { Api } from "../Api";
import { IAccident } from "./IAccident";
import { IAccidentProxyList } from "./IAccidentProxyList";

class AccidentApi {

  // Convert age dropdown value to age range in URL:
  static ageToFields = (value: string, url: URL, fromField: string, toField: string) => {
    if(value == "0") { // 0-15
        url.searchParams.append(toField, '15');
    }
    if(value == "1") { // 16-29
      url.searchParams.append(fromField, '16');
      url.searchParams.append(toField, '29');
    }
    if(value == "2") { // 30-45
      url.searchParams.append(fromField, '30');
      url.searchParams.append(toField, '45');
    }
    if(value == "3") { // 46-59
      url.searchParams.append(fromField, '46');
      url.searchParams.append(toField, '59');
    }
    if(value == "4") { // 60 +
      url.searchParams.append(fromField, '60');
    }
  }

  static list = async (order: string, dir: string, dateFrom: string, dateTo: string, vehicleType: string, driverAge: string, casualtyClass: string, casualtyAge: string, casualtySeverity: string, breathTestPositive: boolean, access_token: string, signal?: AbortSignal): Promise<IAccidentProxyList> => {
    const url = new URL(App.config.apiURL + 'accidents');
    url.searchParams.append('order', order);
    url.searchParams.append('dir', dir);

    // Add filter fields to URL:
    if(dateFrom !== null) url.searchParams.append('dateFrom', dateFrom);
    if(dateTo !== null) url.searchParams.append('dateTo', dateTo);
    if(vehicleType !== null) url.searchParams.append('vehicleType', vehicleType);
    if(driverAge !== null) this.ageToFields(driverAge, url, 'driverAgeFrom', 'driverAgeTo');
    if(casualtyClass !== null) url.searchParams.append('casualtyClass', casualtyClass);
    if(casualtyAge !== null) this.ageToFields(casualtyAge, url, 'casualtyAgeFrom', 'casualtyAgeTo');
    if(casualtySeverity !== null) url.searchParams.append('casualtySeverity', casualtySeverity);
    if(breathTestPositive === true) url.searchParams.append('breathTestPositive', "1");

    return Api.Get<IAccidentProxyList>(url.toString(), access_token, signal);
  }

  static export = async (format: string, order: string, dir: string, dateFrom: string, dateTo: string, vehicleType: string, driverAge: string, casualtyClass: string, casualtyAge: string, casualtySeverity: string, breathTestPositive: boolean, access_token: string, signal?: AbortSignal) => {
    const url = new URL(App.config.apiURL + 'accidents/export');
    url.searchParams.append('format', format);
    url.searchParams.append('order', order);
    url.searchParams.append('dir', dir);

    // Add filter fields to URL:
    if(dateFrom !== null) url.searchParams.append('dateFrom', dateFrom);
    if(dateTo !== null) url.searchParams.append('dateTo', dateTo);
    if(vehicleType !== null) url.searchParams.append('vehicleType', vehicleType);
    if(driverAge !== null) this.ageToFields(driverAge, url, 'driverAgeFrom', 'driverAgeTo');
    if(casualtyClass !== null) url.searchParams.append('casualtyClass', casualtyClass);
    if(casualtyAge !== null) this.ageToFields(casualtyAge, url, 'casualtyAgeFrom', 'casualtyAgeTo');
    if(casualtySeverity !== null) url.searchParams.append('casualtySeverity', casualtySeverity);
    if(breathTestPositive === true) url.searchParams.append('breathTestPositive', "1");

    axios.get(url.toString(), { 
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    .then(response => {
      // Find the content-disposition header.
      let disposition:string = (response as any).headers['content-disposition'];
      // Using a regexp, retrieve the filename from it.
      let regexp = new RegExp('\"(.*)\"');
      let res:RegExpExecArray = regexp.exec(disposition);
      let filename = res[1];
      // Download the file.
      saveAs((response as any).data, filename);
    })
  }


  static get = async (uuid: string, access_token: string, signal?: AbortSignal): Promise<IAccident> => {
    return Api.Get<IAccident>(App.config.apiURL + `accident/${uuid}`, access_token, signal);
  }

  static create = async (user: IAccident, access_token: string, signal?: AbortSignal): Promise<IAccident> => {
    return Api.Post<IAccident>(App.config.apiURL + `accident`, user, access_token, signal);
  }

  static update = async (user: IAccident, access_token: string, signal?: AbortSignal): Promise<IAccident> => {
    return Api.Put<IAccident>(App.config.apiURL + `accident`, user, access_token, signal);
  }

  static delete = async (uuid: string, access_token: string, signal?: AbortSignal) => {
    return Api.Delete(App.config.apiURL + `accident/${uuid}`, access_token, signal);
  }

}

export { AccidentApi }