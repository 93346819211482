import styled from "styled-components"

//
// Contains the filters above a DataTable.
//

const FilterBox = styled.div`
  padding: 12px;
  display: flex;
  justify-content: start;
  gap: 8px;
  flex-wrap: wrap;
  border: solid 1px #eee;
  margin: 16px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
`

export { FilterBox }
