import * as React from 'react';
import { MapboxMap, ViewState } from 'react-map-gl';

import { IAccidentProxy } from "../api/accident/IAccidentProxy";

type TTableState = 'closed' | 'open' | 'full';

interface IFilter {
  accidents: IAccidentProxy[];
  tableState: TTableState;
  setTableState: (state: TTableState) => void;
  viewState: ViewState;
  setViewState: (state: ViewState) => void;
  map: MapboxMap,
  setMap: (map: MapboxMap) => void;
  addAccident: (accident: IAccidentProxy) => void;
  updateAccident: (accident: IAccidentProxy) => void;
  removeAccident: (accident: IAccidentProxy) => void;
  loading: boolean;
  handleExport: (format: string) => void;

  filterDateFrom: string;
  setFilterDateFrom: (value: string) => void;
  filterDateTo: string;
  setFilterDateTo: (value: string) => void;
  filterVehicleType: string;
  setFilterVehicleType: (value: string) => void;
  filterDriverAge: string;
  setFilterDriverAge: (value: string) => void;
  filterCasualtyClass: string;
  setFilterCasualtyClass: (value: string) => void;
  filterCasualtyAge: string;
  setFilterCasualtyAge: (value: string) => void;
  filterCasualtySeverity: string;
  setFilterCasualtySeverity: (value: string) => void;
  filterBreathTestPositive: boolean;
  setFilterBreathTestPositive: (value: boolean) => void;
}

const FilterContext = React.createContext<IFilter>({
  accidents: [],
  tableState: 'closed',
  setTableState: (state: TTableState) => {},
  viewState: null,
  setViewState: (state: ViewState) => {},
  map: null,
  setMap: (map: MapboxMap) => {},
  addAccident: () => {},
  updateAccident: () => {},
  removeAccident: () => {},
  
  loading: false,
  handleExport: () => {},

  filterDateFrom: null,
  setFilterDateFrom: () => {},
  filterDateTo: null,
  setFilterDateTo: () => {},
  filterVehicleType: null,
  setFilterVehicleType: () => {},
  filterDriverAge: null,
  setFilterDriverAge: () => {},
  filterCasualtyClass: null,
  setFilterCasualtyClass: () => {},
  filterCasualtyAge: null,
  setFilterCasualtyAge: () => {},
  filterCasualtySeverity: null,
  setFilterCasualtySeverity: () => {},
  filterBreathTestPositive: null,
  setFilterBreathTestPositive: () => {}
});

export { FilterContext, IFilter, TTableState }
