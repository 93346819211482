import * as React from 'react';
import { Icon } from '@independent-software/typeui/controls';

import { useFilter } from '../../contexts/useFilter';
import { App } from '../../App';
import { TMapStyle } from './TMapStyle';
import { MapButton } from "@independent-software/mapbox-ext/controls/buttons/MapButton";
import { ZoomInButton } from "@independent-software/mapbox-ext/controls/buttons/ZoomInButton";
import { ZoomOutButton } from "@independent-software/mapbox-ext/controls/buttons/ZoomOutButton";
import { CompassButton } from "@independent-software/mapbox-ext/controls/buttons/CompassButton";
import { FullscreenButton } from "@independent-software/mapbox-ext/controls/buttons/FullscreenButton";
import { ScaleControl } from "@independent-software/mapbox-ext/controls/ScaleControl";
import { CompassHint, FullScreenHint, MapStyleHint, ZoomInHint, ZoomOutHint } from './hints';
import { DarkSkin } from '@independent-software/mapbox-ext/types/Skin';
import { Geocoder } from '../../controls/Geocoder';

interface IProps {
  mapStyle: TMapStyle;
  onToggle: () => void;
}

const OFFSET = 20; // Offset from edge of map
const STEP   = 45; // Space between tops of controls

const MapControls = (props: IProps) => {
  const filter = useFilter();

  return (<>
    <ZoomInButton {...filter.viewState} skin={DarkSkin} x={-OFFSET} y={OFFSET} hint={<ZoomInHint/>}/>
    <ZoomOutButton {...filter.viewState} skin={DarkSkin}x={-OFFSET} y={OFFSET+STEP} hint={<ZoomOutHint/>}/>
    <CompassButton {...filter.viewState} skin={DarkSkin} x={-OFFSET} y={OFFSET+STEP*2} visualizePitch hint={<CompassHint/>}/>
    <FullscreenButton skin={DarkSkin} x={-OFFSET} y={OFFSET+STEP*3} hint={<FullScreenHint/>}/>
    <MapButton active={props.mapStyle == 'satellite'} skin={DarkSkin} x={-OFFSET} y={OFFSET+STEP*4} onClick={props.onToggle} hint={<MapStyleHint/>}>
      <Icon name="layers"/>
    </MapButton>
    <ScaleControl {...filter.viewState} x={OFFSET} y={-OFFSET}/>

    {/* <DebugWindow {...viewState} x={OFFSET} y={-OFFSET}/> */}
    <Geocoder {...filter.viewState} skin={DarkSkin} access_token={App.config.mapboxKey} x={OFFSET} y={OFFSET}/>     
  </>)
}

export { MapControls }
