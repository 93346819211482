import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { lighten } from '@independent-software/typeui/helper/lighten';

interface IProps {
  /** @ignore */
  className?: string;
  onClick: () => void;
}

const TableButtonBase = (props: IProps) => {
  return (
    <div className={props.className} onClick={props.onClick}>
      <svg>
        <use xlinkHref={`sprites.svg#list`}></use>
      </svg>      
    </div>
  )
}

const TableButton = styled(TableButtonBase)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background-color: ${p => p.theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 2px 3px rgb(0,0,0,0.3);
  transition: background-color ease-in-out 150ms;
  &:hover {
    background-color: #45bbf2;
  }
  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    fill: #fff;
  }  
`

export { TableButton }
