import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';

import { useAuth } from '../../auth/useAuth';
import { UI } from '../../components/UI/UI';
import { IProfile } from '../../api/profile/IProfile';
import { ProfileApi } from '../../api/profile/ProfileApi';
import { ProfileForm } from './ProfileForm';

type TStep = 'ready' | 'saving' | 'saveError';

const AddProfile = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const currentProfile = React.useRef<IProfile>({ 
    name: null, 
    can_view_users: false,
    can_edit_users: false,
    can_view_profiles: false,
    can_edit_profiles: false,
    can_view_logs: false,
    can_edit_accidents: false
  } as any);
  const [profile, setProfile] = React.useState<IProfile>(currentProfile.current);
  const [step, setStep] = React.useState<TStep>("ready");
  const [dirty, setDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState(null)

  const handleChange = (newProfile: IProfile, forceupdate: boolean) => {
    currentProfile.current = Object.assign(currentProfile.current, newProfile);
  }

  const handleValidate = (valid: boolean) => {
    setIsValid(valid);
  }  

  const handleSubmit = () => {
    setProfile(currentProfile.current);
    setDirty(true);
    if(!isValid) return;
    setError(null);
    setStep('saving');

    ProfileApi.create(currentProfile.current, auth.access_token)
    .then(() => {
      ToastService.toast("Profile created successfully.");
      navigate("/profiles");
    })
    .catch(error => {
      setStep('saveError');
      setError(error);
    });
  }

  const handleCancelSave = () => {
    setStep('ready');
  }

  return (
    <UI.PaddedScreen>
      <UI.Header>Add Profile</UI.Header>
      {step == 'saving' && <Loader></Loader>}
      <UI.Content>
        <ProfileForm dirty={dirty} data={profile} onChange={handleChange} onValidate={handleValidate}/>
      </UI.Content>
      <Dialog.Xhr open={step == 'saveError'} error={error} onClose={handleCancelSave} onRetry={handleSubmit}/>
      <UI.Footer>
        <Button secondary onClick={() => navigate("/profiles")}>Cancel</Button>
        <Button primary disabled={!isValid} onClick={handleSubmit}><Icon name="save"/> Save profile</Button>
      </UI.Footer>
    </UI.PaddedScreen>
  );
}

export { AddProfile };
