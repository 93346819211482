import * as React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, Icon, Label, Loader, Message, Tabs, ToastService } from '@independent-software/typeui/controls';

import { useAuth } from '../../auth/useAuth';
import { useFilter } from '../../contexts/useFilter';
import { IAccident } from '../../api/accident/IAccident';
import { AccidentApi } from '../../api/accident/AccidentApi';
import { UI } from '../../components/UI/UI';
import { Timestamp } from '../../controls/Timestamp';
import { TableLocation } from './tables/TableLocation';
import { TableSituation } from './tables/TableSituation';
import { TableVehicle } from './tables/TableVehicle';
import { TableCasualty } from './tables/TableCasualty';
import { TableFactors } from './tables/TableFactors';

type TStep = 'loading' | 'ready' | 'confirm' | 'loadError' | 'deleting' | 'deleteError';

const ViewAccident = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const filter = useFilter();

  const [step, setStep] = React.useState<TStep>('ready');
  const [accident, setAccident] = React.useState<IAccident>();
  const [error, setError] = React.useState(null);

  const loadAccident = () => {
    setStep('loading');
    setError(null);
    AccidentApi.get(params.uuid, auth.access_token)
    .then(res => {
      setAccident(res);
      setStep('ready');
    })
    .catch((err) => {
      setStep('loadError');
      setError(err);
    });
  }

  React.useEffect(() => loadAccident(), []);

  const handleDelete = () => {
    setStep('deleting');
    AccidentApi.delete(accident.uuid, auth.access_token)
    .then(() => {
      ToastService.toast("Accident deleted successfully.");
      // Remove accident from global accident list:
      filter.removeAccident({ uuid: accident.uuid });
      // Return to home.
      navigate("/");
    })
    .catch((err) => {
      setStep('deleteError');
      setError(err);
    });
  }
  
  return (
    <>
      {(step == 'loading' || step == 'deleting') && <Loader/>}
      {accident != null && <UI.PaddedScreen>
        <UI.Header>View Accident</UI.Header>
        <UI.Content>
          <Tabs underlined>
            <Tabs.Pane label="Location">        
              <Scroller>
                <TableLocation accident={accident}/>
              </Scroller>
            </Tabs.Pane>
            <Tabs.Pane label="Situation">
              <Scroller>
                <TableSituation accident={accident}/>
              </Scroller>
            </Tabs.Pane>
            <Tabs.Pane label={<>Vehicles <Label size="small">{accident.vehicles.length}</Label></>}>
              <Scroller>
                {accident.vehicles.length == 0 && <>
                  <Message type="info">
                    No vehicles have been recorded for this accident.
                  </Message>
                </>}            
                {accident.vehicles.map((vehicle, idx) => 
                  <UI.Block key={idx} secondary title={<span>Vehicle {idx+1}</span>}>
                    <TableVehicle accident={accident} vehicle={vehicle}/>
                  </UI.Block>
                )}
              </Scroller>
            </Tabs.Pane>
            <Tabs.Pane label={<>Casualties <Label size="small">{accident.casualties.length}</Label></>}>
              <Scroller>
                {accident.casualties.length == 0 && <>
                  <Message type="info">
                    No casualties have been recorded for this accident.
                  </Message>
                </>}            
                {accident.casualties.map((casualty, idx) => 
                  <UI.Block key={idx} secondary title={<span>Casualty {idx+1}</span>}>
                    <TableCasualty casualty={casualty}/>
                  </UI.Block>
                )}
              </Scroller>
            </Tabs.Pane>
            <Tabs.Pane label={<>Contributory Factors <Label size="small">{accident.factors.length}</Label></>}>
              <Scroller>
                {accident.factors.length == 0 && <>
                  <Message type="info">
                    No contributory factors have been recorded for this accident.
                  </Message>
                </>}
                <TableFactors factors={accident.factors}/>
              </Scroller>
            </Tabs.Pane>        
            <Tabs.Pane label="Comments">
              <Scroller>
                {accident.general_comment && accident.general_comment.split("\n").map((part, index) => <p key={index}>{part}</p>)}
              </Scroller>
            </Tabs.Pane>
          </Tabs>
        </UI.Content>
        <UI.Footer>
          <UI.FooterSection>
            {auth.rights.includes('can_edit_users') && <>
              {step != 'confirm' && <Button negative disabled={step == 'deleting'} onClick={() => setStep('confirm')}><Icon name="trash"/> Delete accident</Button>}
              {step == 'confirm' && <Button negative onClick={handleDelete}><Icon name="trash"/> Are you sure?</Button>}
            </>}
            {accident ? <Timestamp model={accident}/> : null}
          </UI.FooterSection>
          <UI.FooterSection>
            {auth.rights.includes('can_edit_accidents') && <Button primary onClick={() => navigate(`/accident/edit/${accident.uuid}`)}><Icon name="edit"/> Edit accident</Button>}
          </UI.FooterSection>
        </UI.Footer>
      </UI.PaddedScreen>}

      <Dialog.Xhr open={step == 'loadError'}   error={error} onClose={() => setStep('ready')} onRetry={loadAccident}/>
      <Dialog.Xhr open={step == 'deleteError'} error={error} onClose={() => setStep('ready')} onRetry={handleDelete}/>
    </>
  )
}

const Scroller = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 48px;
`

export { ViewAccident }
