import * as React from 'react';
import styled from 'styled-components';

import { Icon } from '@independent-software/typeui/controls';

interface IProps { 
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Check value (true green, false red). */
  value: boolean;  
}

//
// Shows a check/times icon with a label. The icon is colored.
//

const ColorCheckBase = (props: IProps) =>
  <div className={props.className}>
    <Icon circular color={props.value ? 'green' : 'darkred'} name={props.value ? 'check' : 'times'} />
    {props.children}
  </div>

const ColorCheck = styled(ColorCheckBase)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

export { ColorCheck }
