import * as React from 'react';
import { Flex, Message, Table } from '@independent-software/typeui/controls';

import { UI } from '../../../components/UI/UI';
import { Answer } from '../controls/Answer';
import { IVehicle } from '../../../api/accident/IVehicle';
import { Lookup } from '../Lookup';
import { ICasualty } from '../../../api/accident/ICasualty';

interface IProps {
  casualty: ICasualty;
}

const TableCasualty = (props: IProps) => 
  <Flex.Columns count={2}>
    
    <div>
      <UI.Block title="Casualty Information">
        <Table striped transparent>
          <tbody>
            <Answer label="Casualty Class" value={props.casualty.class} src={Lookup.getCasualtyClass}/>
            {(props.casualty.class == 0 || props.casualty.class == 1) && <Answer label="Vehicle" value={props.casualty.vehicle}/>}
            <Answer label="Casualty Name" value={props.casualty.name}/>
            <Answer label="Casualty Address" value={props.casualty.address}/>
            <Answer label="Sex of Casualty" value={props.casualty.sex} src={Lookup.getSex}/>
            <Answer label="Age of Casualty" value={props.casualty.age}/>
            <Answer label="Casualty of Casualty" value={props.casualty.severity} src={Lookup.getCasualtySeverity}/>
          </tbody>
        </Table>      
      </UI.Block>

      <UI.Block title="Situational Information">
        <Table striped transparent>
          <tbody>
            <Answer label="Cycle Helmet Worn" value={props.casualty.helmet} src={Lookup.getCycleHelmet}/>
            <Answer label="Clothing Worn" value={props.casualty.clothing} src={Lookup.getClothing}/>
            <Answer label="Car Passenger?" value={props.casualty.car_passenger} src={Lookup.getCarPassenger}/>
            <Answer label="Bus or Coach Passenger?" value={props.casualty.bus_passenger} src={Lookup.getBusPassenger}/>
            <Answer label="Seat Belt in Use" value={props.casualty.seat_belt} src={Lookup.getSeatBelt}/>
          </tbody>
        </Table>      
      </UI.Block>      

    </div>

    <div>
      {props.casualty.class == 2 && 
      <UI.Block title="Pedestrian Casualty">

        <Message type="info">
          This information is only applicable to pedestrian casualties.
        </Message>

        <Table striped transparent>
          <tbody>
            <Answer label="Pedestrian Liability" value={props.casualty.pedestrian_liability} src={Lookup.getLiability}/>
            <Answer label="Pedestrian Location" value={props.casualty.pedestrian_location} src={Lookup.getPedestrianLocation}/>
            <Answer label="Pedestrian Direction" value={props.casualty.pedestrian_direction} rose/>
            <Answer label="Pedestrian Movement" value={props.casualty.pedestrian_movement} src={Lookup.getPedestrianMovement}/>
            <Answer label="Pedestrian Road Maintenance Worker" value={props.casualty.pedestrian_worker} src={Lookup.getPedestrianRoadMaintenanceWorker}/>
          </tbody>
        </Table>      
      </UI.Block>}
    </div>

  </Flex.Columns>

export { TableCasualty }
