import * as React from 'react'
import styled from '@independent-software/typeui/styles/Theme';

import { Button } from '@independent-software/typeui/controls/Button';
import { useAuth } from '../../auth/useAuth';
import { MenuIcon } from './MenuIcon';
import { Logo } from './Logo';
import { Separator } from './Separator';
import { useNavigate } from 'react-router-dom';

interface IProps {
  /** @ignore */
  className?: string;
}

const MainMenuBase = (props: IProps) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleMap = () => {
    navigate("/");
  }

  const handleAddAccident = () => {
    navigate("/accident/add");
  }

  const handleUsers = () => {
    navigate("/users");
  }

  const handleProfiles = () => {
    navigate("/profiles");
  }

  const handleLog = () => {
    navigate("/logs");
  }

  const handleAccount = () => {
    navigate("/account");
  }

  const handleSignout = () => {
    auth.signout();
  }

  return (
    <div className={props.className}>
      <Logo/>
      <MenuIcon title="Map" svg="map" onClick={handleMap}/>
      <MenuIcon title="Record accident" svg="accident" onClick={handleAddAccident}/>
      <Separator/>
      {auth.rights.includes('can_view_users') && <MenuIcon title="Users" svg="users" onClick={handleUsers}/>}
      {auth.rights.includes('can_view_profiles') && <MenuIcon title="Profiles" svg="profiles" onClick={handleProfiles}/>}
      {auth.rights.includes('can_view_logs') && <MenuIcon title="Activity Log" svg="logs" onClick={handleLog}/>}
      <MenuIcon title="My Account" svg="account" onClick={handleAccount}/>
      <MenuIcon title="Sign out" svg="signout" onClick={handleSignout}/>
    </div>
  );
}

const MainMenu = styled(MainMenuBase)`
  position: absolute;
  z-index: 9998;
  left: 0;
  top: 0;
  width: 75px;
  height: 100%;
  background: linear-gradient(#457499, #2B4151);
  padding-top: 32px;
  padding-bottom: 32px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 12px;
  gap: 16px;
`

export { MainMenu }
