import * as React from 'react';
import styled from 'styled-components';

const Footer = styled.div`
  height: 68px;
  min-height: 68px;
  display: flex;
  flex-direction: row;
  background: #eee;
  border-top: solid 1px #ccc;
  justify-content: space-between;
  padding: 0 16px 0 16px;
  align-items: center;
`

const FooterSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export { Footer, FooterSection }
