import * as React from 'react';
import styled from 'styled-components';

import { Form } from '@independent-software/typeui/controls';
import { Password } from '@independent-software/typeui/modules/Password';

import { IAccount } from '../../api/user/IAccount';

interface IProps {
  account: IAccount;
  onChange: (account: IAccount) => void;
  onValidate: (valid: boolean) => void;  
}

const AccountForm = (props: IProps) => {

  return (
    <DialogPadding>
      <Form data={props.account} onChange={props.onChange} onValidate={props.onValidate}>
        <Form.Field
          required={{message: "Current password is required."}}
          width={1} label="Password"
          name="password" 
          value={props.account.password}
          control={<Password autocomplete="off" fluid placeholder="Password"/>}
          hint="Please enter your current password."
        />  

        <Form.Field
          required={{message: "New password is required."}}
          minLength={{length: 8, message: "New password must be at least 8 characters."}}
          maxLength={{length: 30, message:"New password may not be longer than 30 characters."}}
          noPattern={{pattern: '^(.{0,7}|.{31,}|[^0-9]*|[^A-Za-z]*|[a-zA-Z0-9]*)$', message: "New password must contain at least one digit and one special character."}}            
          width={1} label="New password"
          name="new_password" 
          value={props.account.password}
          control={<Password autocomplete="off" fluid placeholder="New password"/>}
          hint="Please enter your new password."
        />

      </Form>
    </DialogPadding>
  )
}

const DialogPadding = styled.div`
  padding: 16px;
`

export { AccountForm }
