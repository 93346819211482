import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Fired when button is clicked. If not specified, control will be read-only. */
  onClick?: () => void;
  /** Is the button currently depressed? */
  selected?: boolean;
}

const FactorButtonBase = (props: IProps) =>
  <div className={props.className} onClick={props.onClick ? props.onClick : null}>
    {props.children}
  </div>

const FactorButton = styled(FactorButtonBase)`
  width: 200px;
  border-radius: 8px;
  border: solid 2px #aaa;
  padding: 8px 8px 8px 8px;
  font-size: 12px;
  ${p => p.onClick && css`
    cursor: pointer;
    &:hover {
      background-color: ${p => p.theme.primaryColor};
      border-color: ${p => p.theme.primaryColor};
      color: #fff;
    }
  `}
  ${p => p.selected && css`
    background-color: #333;
    border-color: #333;
    color: #fff;
    ${p.onClick && css`
      &:hover {
        background-color: #666;
        border-color: #666;
      }
    `}
  `}
`

export { FactorButton }
