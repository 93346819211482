import * as React from 'react';
import styled from 'styled-components';
import { RoseButton } from '../controls/RoseButton';

interface IProps {
  /** @ignore */
  className?: string;
  /** Current value */
  value?: number;
  /** Fired when value changes. If not specified, control will be read-only. */
  onChange?: (val: number) => void;
}

const RoseBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <RoseButton x={0} y={0} selected={props.value == 8} onClick={props.onChange ? () => props.onChange(8) : null} title="Northwest">NW</RoseButton> 
      <RoseButton x={2} y={0} selected={props.value == 2} onClick={props.onChange ? () => props.onChange(2) : null} title="Northeast">NE</RoseButton> 
      <RoseButton x={1} y={0} selected={props.value == 1} onClick={props.onChange ? () => props.onChange(1) : null} title="North">N</RoseButton> 
      <RoseButton x={0} y={1} selected={props.value == 7} onClick={props.onChange ? () => props.onChange(7) : null} title="West">W</RoseButton> 
      <RoseButton x={1} y={1} selected={props.value == 0} onClick={props.onChange ? () => props.onChange(0) : null} title="Parked/Standing still">P</RoseButton> 
      <RoseButton x={2} y={1} selected={props.value == 3} onClick={props.onChange ? () => props.onChange(3) : null} title="East">E</RoseButton> 
      <RoseButton x={0} y={2} selected={props.value == 6} onClick={props.onChange ? () => props.onChange(6) : null} title="Southwest">SW</RoseButton> 
      <RoseButton x={1} y={2} selected={props.value == 5} onClick={props.onChange ? () => props.onChange(5) : null} title="South">S</RoseButton> 
      <RoseButton x={2} y={2} selected={props.value == 4} onClick={props.onChange ? () => props.onChange(4) : null} title="West">SE</RoseButton> 
    </div>
  )
}

const Rose = styled(RoseBase)`
  position: relative;
  width: 99px;
  height: 99px;
`

export { Rose }
