import { App } from "../../App";
import { Api } from "../Api";
import { IProfile } from "../profile/IProfile";
import { IAccount } from "./IAccount";
import { IUser } from "./IUser";
import { IUserList } from "./IUserList";

class UserApi {

  static list = async (order: string, dir: string, offset: number, count: number, q: string, profileUUID: string, access_token: string, signal?: AbortSignal): Promise<IUserList> => {
    const url = new URL(App.config.apiURL + 'users');
    url.searchParams.append('order', order);
    url.searchParams.append('dir', dir);
    url.searchParams.append('offset', offset.toString());
    url.searchParams.append('count', count.toString());
    if(q) url.searchParams.append('q[]', `like-${q}`);
    if(profileUUID) url.searchParams.append('profile[]', `eq-${profileUUID}`);
    return Api.Get<IUserList>(url.toString(), access_token, signal);
  }

  static get = async (uuid: string, access_token: string, signal?: AbortSignal): Promise<IUser> => {
    return Api.Get<IUser>(App.config.apiURL + `user/${uuid}`, access_token, signal);
  }

  static create = async (user: IUser, access_token: string, signal?: AbortSignal): Promise<IUser> => {
    return Api.Post<IUser>(App.config.apiURL + `user`, user, access_token, signal);
  }

  static update = async (user: IUser, access_token: string, signal?: AbortSignal): Promise<IUser> => {
    return Api.Put<IUser>(App.config.apiURL + `user`, user, access_token, signal);
  }

  static delete = async (uuid: string, access_token: string, signal?: AbortSignal) => {
    return Api.Delete(App.config.apiURL + `user/${uuid}`, access_token, signal);
  }

  static change_password = async(account: IAccount, access_token: string, signal?: AbortSignal) => {
    return Api.Post(App.config.apiURL + `account`, account, access_token, signal);
  }

}

export { UserApi }