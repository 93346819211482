import * as React from 'react';
import styled from 'styled-components';
import { Popup } from 'react-map-gl';
import { Label, Table } from '@independent-software/typeui/controls';
import { Latitude, Longitude } from '@independent-software/typeui/formatters/GIS'

import { IAccidentProxy } from '../../api/accident/IAccidentProxy';


interface IProps {
  accident: IAccidentProxy;
}

const MapPopup = (props: IProps) => {
  return (
    <Popup longitude={props.accident.longitude} latitude={props.accident.latitude} offset={25} closeButton={false} style={{zIndex: 1000}}>
      <Header>ACCIDENT DETAILS</Header>
      <Table striped transparent>
        <tbody>
          <tr>
            <td>Latitude</td><td><Latitude value={props.accident.latitude}/></td>
          </tr>
          <tr>
            <td>Longitude</td><td><Longitude value={props.accident.longitude}/></td>
          </tr>        
          <tr>
            <td>Town</td><td>{props.accident.general_town ?? <Label size="small">(unset)</Label>}</td>
          </tr>
          <tr>
            <td>Vehicles</td><td>{props.accident.vehicles_count}</td>
          </tr>
          <tr>
            <td>Casualties</td><td>{props.accident.casualties_count}</td>
          </tr>
        </tbody>
      </Table>
    </Popup>
  )
}

const Header = styled.div`
  font-size: 12px;
  font-weight: bold;
`

export { MapPopup }
