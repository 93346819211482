import { App } from "../../App";
import { Api } from "../Api";
import { ILogList } from "./ILogList";

class LogApi {

  static list = async (userUUID: string, order: string, dir: string, offset: number, count: number, q: string, fromDate: string, toDate: string, access_token: string, signal?: AbortSignal): Promise<ILogList> => {
    const url = new URL(App.config.apiURL + 'log');
    if(userUUID) url.searchParams.append('user[]', `eq-${userUUID}`);
    url.searchParams.append('order', order);
    url.searchParams.append('dir', dir);
    url.searchParams.append('offset', offset.toString());
    url.searchParams.append('count', count.toString());
    if(q) url.searchParams.append('q[]', `like-${q}`);
    if(fromDate) url.searchParams.append('date[]', `gte-${fromDate}`);
    if(toDate) url.searchParams.append('date[]', `lte-${toDate}`);
    return Api.Get<ILogList>(url.toString(), access_token, signal);
  }

}

export { LogApi }
