import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
}

const FullScreenHintBase = (props: IProps) => {
  return (
    <div className={props.className}>
      Toggle fullscreen map
    </div>
  );
}

const FullScreenHint = styled(FullScreenHintBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export { FullScreenHint }
