import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, Icon, Label, List, Loader, Placeholder, Table, Tabs, ToastService } from '@independent-software/typeui/controls';

import { UI } from '../../components/UI/UI';
import { useAuth } from '../../auth/useAuth';
import { Timestamp } from '../../controls/Timestamp';
import { ProfileApi } from '../../api/profile/ProfileApi';
import { IProfile } from '../../api/profile/IProfile';
import { ColorCheck } from '../../controls/ColorCheck';
import { UserList } from '../users/UserList';

type TStep = 'loading' | 'ready' | 'confirm' | 'loadError' | 'deleting' | 'deleteError';

const ViewProfile = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  const [step, setStep] = React.useState<TStep>('ready');
  const [profile, setProfile] = React.useState<IProfile>();
  const [error, setError] = React.useState(null);
  const [userCount, setUserCount] = React.useState<number>(null);

  const loadProfile = () => {
    setStep('loading');
    setError(null);
    ProfileApi.get(params.uuid, auth.access_token)
    .then(res => {
      setProfile(res);
      setStep('ready');
    })
    .catch((err) => {
      setStep('loadError');
      setError(err);
    });
  }

  React.useEffect(() => loadProfile(), []);

  const handleDelete = () => {
    setStep('deleting');
    ProfileApi.delete(profile.uuid, auth.access_token)
    .then(res => {
      ToastService.toast("Profile deleted successfully.");
      navigate("/profiles");
    })
    .catch((err) => {
      setStep('deleteError');
      setError(err);
    });
  }
  
  return (
    <>
      {(step == 'loading' || step == 'deleting') && <Loader/>}
      <UI.PaddedScreen>
        <UI.Header>View Profile</UI.Header>
        <Tabs underlined>
          <Tabs.Pane label="Profile">
            <UI.Content>
              <UI.Block title="Profile data">
                {profile == null && 
                  <Placeholder.Paragraph>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                  </Placeholder.Paragraph>}
                {profile != null && <>
                  <Table striped transparent>
                    <tbody>
                      <tr>
                        <td>Name</td><td><b>{profile.name}</b></td>
                      </tr>
                      <tr>
                        <td>Users</td><td>{profile.users_count}</td>
                      </tr>                  
                    </tbody>
                  </Table>
                </>}
              </UI.Block>
              <UI.Block title="Permissions">
                {profile == null && 
                  <Placeholder.Paragraph>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                  </Placeholder.Paragraph>}
                {profile != null && <>
                  <List relaxed>
                    <List.Item><ColorCheck value={profile.can_view_users}>View users</ColorCheck></List.Item>
                    <List.Item><ColorCheck value={profile.can_edit_users}>Edit users</ColorCheck></List.Item>
                    <List.Item><ColorCheck value={profile.can_view_profiles}>View profiles</ColorCheck></List.Item>
                    <List.Item><ColorCheck value={profile.can_edit_profiles}>Edit profiles</ColorCheck></List.Item>
                    <List.Item><ColorCheck value={profile.can_view_logs}>View logs</ColorCheck></List.Item>
                    <List.Item><ColorCheck value={profile.can_edit_accidents}>Edit accidents</ColorCheck></List.Item>
                  </List>
                </>}
              </UI.Block>
            </UI.Content>
          </Tabs.Pane>
          <Tabs.Pane label={<>Users {userCount !== null && <Label size="small">{userCount}</Label>}</>}>
            {profile != null && <UserList profileUUID={profile.uuid} countCallback={(count) => setUserCount(count)}/>}
          </Tabs.Pane>
        </Tabs>

        <UI.Footer>
          <UI.FooterSection>
            {auth.rights.includes('can_edit_profiles') && <>
              {step != 'confirm' && <Button negative disabled={step == 'deleting'} onClick={() => setStep('confirm')}><Icon name="trash"/> Delete profile</Button>}
              {step == 'confirm' && <Button negative onClick={handleDelete}><Icon name="trash"/> Are you sure?</Button>}
            </>}
            {profile ? <Timestamp model={profile}/> : null}
          </UI.FooterSection>
          <UI.FooterSection>
            {auth.rights.includes('can_edit_profiles') && <Button primary onClick={() => navigate(`/profile/edit/${profile.uuid}`)}><Icon name="edit"/> Edit profile</Button>}
          </UI.FooterSection>
        </UI.Footer>
      </UI.PaddedScreen>

      <Dialog.Xhr open={step == 'loadError'}   error={error} onClose={() => setStep('ready')} onRetry={loadProfile}/>
      <Dialog.Xhr open={step == 'deleteError'} error={error} onClose={() => setStep('ready')} onRetry={handleDelete}/>
    </>
  )
}

export { ViewProfile }
