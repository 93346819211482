import * as React from 'react';
import { FeatureCollection } from 'geojson';
import Map, { Layer, MapLayerMouseEvent, MapboxGeoJSONFeature, MapboxMap, Source, ViewStateChangeEvent } from 'react-map-gl';

import { App } from '../../App';
import { useFilter } from '../../contexts/useFilter';
import { useNavigate } from 'react-router-dom';
import { TMapStyle } from './TMapStyle';
import { MapControls } from './MapControls';
import { IAccidentProxy } from '../../api/accident/IAccidentProxy';
import { MapPopup } from './MapPopup';

import { ClustersCircleLayer } from './layers/ClustersCircleLayer';
import { ClustersSymbolLayer } from './layers/ClusterSymbolLayer';
import { ClustersStrokeLayer } from './layers/ClustersStrokeLayer';
import { CLUSTER_CUTOFF } from './layers/ClusterConstants';
import { MarkersCircleLayer } from './layers/MarkersCircleLayer';
import { MarkersStrokeLayer } from './layers/MarkersStrokeLayer';
import { MarkersStrokeHoverLayer } from './layers/MarkersStrokeHoverLayer';
import { MarkersCircleHoverLayer } from './layers/MakersCircleHoverLayer';

const MyMap = () => {
  const map = React.useRef<MapboxMap>(null);
  const filter = useFilter();
  const navigate = useNavigate();
  const [mapStyle, setMapStyle] = React.useState<TMapStyle>('satellite');
  const [hovered, setHovered] = React.useState<MapboxGeoJSONFeature>(null);
  const [accident, setAccident] = React.useState<IAccidentProxy>(null);

  const handleLoad = (e: mapboxgl.MapboxEvent) => {
    map.current = e.target;
    filter.setMap(e.target);

    // Add terrain:
    map.current.addSource('mapbox-dem', {
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.terrain-rgb'
    });
    map.current.setTerrain({
      'source': 'mapbox-dem',
      'exaggeration': 1.5
    });     
  }

  const handleToggleStyle = () => {
    if(mapStyle == 'streets') setMapStyle('satellite');
    else setMapStyle('streets');
  } 
  
  const getStyleURL = (): string => {
    return mapStyle == 'satellite' 
      ? 'mapbox://styles/longlineenvironment/clqiebpfo00lq01o35n9hhc6h' 
      : 'mapbox://styles/longlineenvironment/clqiea8ix00m801nwe6pn6ajc';
  }

  const handleMove = (e: ViewStateChangeEvent) => filter.setViewState(e.viewState);

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    // Abort if map not yet loaded.
    if(!map.current) return;

    // Clear previously-hovered feature:
    if(hovered != null) map.current.setFeatureState(hovered, { hover: false });
    setAccident(null);
    setHovered(null);

    // Any feature currently hovered? If not, abort.
    if(!e.features || e.features.length == 0) return;

    // Store currently-hovered feature:
    const feature: MapboxGeoJSONFeature = e.features[0];
    setHovered(feature);
    setAccident(filter.accidents.find(a => a.uuid == feature.properties['uuid']));
    map.current.setFeatureState(feature, { hover: true });
  }

  const handleClick = (e: MapLayerMouseEvent) => {
    // Abort if map not yet loaded.
    if(!map.current) return;

    // Any feature clicked? If not, abort.
    if(!e.features || e.features.length == 0) return;    

    // Get clicked feature.
    const feature: MapboxGeoJSONFeature = e.features[0];    
    const accident = filter.accidents.find(a => a.uuid == feature.properties['uuid']);

    map.current.panTo([ accident.longitude, accident.latitude ]);
    navigate(`/accident/${accident.uuid}`);
  }

  const geojson: FeatureCollection = {
    type: "FeatureCollection",
    features: filter.accidents.map(a => { return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [a.longitude, a.latitude]
        },
        properties: { uuid: a.uuid }
      }
    })
  }

  return (
    <Map
      {...filter.viewState}
      mapboxAccessToken={App.config.mapboxKey}
      style={{width: '100%', height: '100%'}}
      logoPosition="bottom-right"
      interactiveLayerIds={['markers-circles', 'markers-circles-hover']}
      minZoom={4}
      attributionControl={false}
      scrollZoom={true}
      mapStyle={getStyleURL()}
      cursor={hovered != null ? 'pointer' : 'auto'}
      onLoad={handleLoad}
      onMove={handleMove}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      <MapControls mapStyle={mapStyle} onToggle={handleToggleStyle}/>

      <Source generateId type="geojson" data={geojson} cluster clusterMaxZoom={CLUSTER_CUTOFF-1} clusterRadius={80}>
        <Layer {...ClustersCircleLayer} filter={['has', 'cluster']}/>
        <Layer {...ClustersStrokeLayer} filter={['has', 'cluster']}/>
        <Layer {...ClustersSymbolLayer} filter={['has', 'cluster']}/>

        <Layer {...MarkersCircleLayer} filter={['all', ['!', ['has', 'cluster']], ['!=', ['get', 'uuid'], hovered ? hovered.properties.uuid : null ]]}/>
        <Layer {...MarkersCircleHoverLayer} filter={['all', ['!', ['has', 'cluster']], ['==', ['get', 'uuid'], hovered ? hovered.properties.uuid : null ]]}/>
        <Layer {...MarkersStrokeLayer} filter={['all', ['!', ['has', 'cluster']], ['!=', ['get', 'uuid'], hovered ? hovered.properties.uuid : null ]]}/>
        <Layer {...MarkersStrokeHoverLayer} filter={['all', ['!', ['has', 'cluster']], ['==', ['get', 'uuid'], hovered ? hovered.properties.uuid : null ]]}/>
      </Source>

      {accident != null && <MapPopup accident={accident}/>}

    </Map>    
  )
}


export { MyMap }
