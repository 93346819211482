import * as React from 'react';

import { Input } from '@independent-software/typeui/controls/Input';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown, Flex } from '@independent-software/typeui/controls';
import { UI } from '../../../components/UI/UI';
import { Lookup } from '../Lookup';
import { IVehicle } from '../../../api/accident/IVehicle';
import { Rose } from '../controls/Rose';
import { IAccident } from '../../../api/accident/IAccident';

interface IProps {
  /** Initial form props.data. */
  index: number;
  data: IVehicle;
  accident: IAccident;
}

const FormVehicle = (props: IProps) => {

  return (
    <Flex.Columns count={2}>

      <div>
        <UI.Block title="Vehicle Information">
          <Form.Field
            width={1} label="Vehicle Registration"
            name={`vehicles[${props.index}].registration`}
            value={props.data.registration}
            control={(<Input type="text" placeholder="Vehicle Registration" fluid/>)}
            hint="Please enter the vehicle registration mark."/>

         <Form.Field
            width={1} label="Right-Hand Drive?"
            name={`vehicles[${props.index}].right_hand`}
            value={props.data.right_hand}
            control={Lookup.getDropdown(Lookup.getRightHand, "Right-Hand Drive?")}
            hint="Please select whether the vehicle was right-hand drive."/>

         <Form.Field
            width={1} label="Type of Vehicle"
            name={`vehicles[${props.index}].type`}
            forceupdate
            value={props.data.type}
            control={Lookup.getDropdown(Lookup.getVehicleType, "Type of Vehicle")}
            hint="Please select the type of vehicle."/>

          <Form.Field
            width={1} label="Towing or Articulation"
            name={`vehicles[${props.index}].towing_articulation`}
            value={props.data.towing_articulation}
            control={Lookup.getDropdown(Lookup.getTowingArticulation, "Towing or Articulation")}
            hint="Please select towing and articulation."/>

          {/* Field is only shown for vehicle types with windows: */}
          {[0,1,2,3,4,5,6,14,15].includes(props.data.type) && 
            <Form.Field
              width={1} label="Tinted windows"
              name={`vehicles[${props.index}].tinted_windows`}
              value={props.data.tinted_windows}
              control={Lookup.getDropdown(Lookup.getYesNo, "Tinted windows")}
              hint="Please indicate whether the vehicle had tinted windows."/>            
          }

        </UI.Block>

        <UI.Block title="Driver Information">

          <Form.Field
            width={1} label="Sex of Driver"
            name={`vehicles[${props.index}].driver_sex`}
            value={props.data.driver_sex}
            control={Lookup.getDropdown(Lookup.getSex, "Sex of Driver")}
            hint="Please select the sex of the driver."/>

          <Form.Field
            width={1} label="Ethnicity of Driver"
            name={`vehicles[${props.index}].driver_ethnicity`}
            value={props.data.driver_ethnicity}
            control={Lookup.getDropdown(Lookup.getEthnicity, "Ethnicity of Driver")}
            hint="Please select the ethnicity of the driver."/>

          <Form.Field
            width={1} label="Guyanese National?"
            name={`vehicles[${props.index}].driver_national`}
            value={props.data.driver_national}
            forceupdate
            control={Lookup.getDropdown(Lookup.getYesNo, "Guyanese National?")}
            hint="Is the driver a guyanese national?"/>

          {props.data.driver_national == 1 && <Form.Field
            width={1} label="Nationality of Driver"
            name={`vehicles[${props.index}].driver_nationality`}
            value={props.data.driver_nationality}
            control={Lookup.getDropdown(Lookup.getNationality, "Nationality of Driver")}
            hint="Please select the nationality of the driver."/>}

          <Form.Field
            width={1} label="Driver's Date of Birth"
            name={`vehicles[${props.index}].driver_dob`}
            value={props.data.driver_dob}
            control={<Input clearable placeholder="Date of Birth" type="date"/>}
            forceupdate
            hint="Please select the sex of the driver (skip if not known)."/>

          {!props.data.driver_dob && <Form.Field
            isInt={{ message: "Age must be a whole number."}}
            min={{ value: 0, message: "Age must be at least 0."}}
            max={{ value: 150, message: "Age may not be greater than 150."}}
            width={1} label="Age of Driver"
            name={`vehicles[${props.index}].driver_age`}
            value={props.data.driver_age}
            control={(<Input type="text" placeholder="Age of Driver" fluid/>)}
            hint="Please enter the driver's age (estimate if necessary)."/>}

          <Form.Field
            maxLength={{ length: 255, message: "Name may not be longer than 255 characters."}}
            width={1} label="Driver Name"
            name={`vehicles[${props.index}].driver_name`}
            value={props.data.driver_name}
            control={(<Input type="text" placeholder="Driver Name" fluid/>)}
            hint={<>Please enter driver's name.</>}/>

          <Form.Field
            maxLength={{ length: 255, message: "Address may not be longer than 255 characters."}}
            width={1} label="Driver Address"
            name={`vehicles[${props.index}].driver_address`}
            value={props.data.driver_address}
            control={(<Input type="text" placeholder="Driver Address" fluid/>)}
            hint={<>Please enter driver's address.</>}/>

          <Form.Field
            width={1} label="Licence Status"
            name={`vehicles[${props.index}].driver_licence`}
            value={props.data.driver_licence}
            control={Lookup.getDropdown(Lookup.getLicence, "Driver licence")}
            hint="Please select the driver's licence type."/>

          <Form.Field
            width={1} label="Breath Test"
            name={`vehicles[${props.index}].driver_breath_test`}
            value={props.data.driver_breath_test}
            control={Lookup.getDropdown(Lookup.getBreathTest, "Breath Test")}
            hint="Please select a breath test result."/>

         <Form.Field
            width={1} label="Journey Purpose"
            name={`vehicles[${props.index}].driver_purpose`}
            value={props.data.driver_purpose}
            control={Lookup.getDropdown(Lookup.getJourneyPurpose, "Journey Purpose")}
            hint="Please select the driver/rider's journey purpose."/>

        </UI.Block>

      </div>
      <div>

        <UI.Block title="Involvement">

          <Form.Field
            width={1} label="Liability"
            name={`vehicles[${props.index}].driver_liability`}
            value={props.data.driver_liability}
            control={Lookup.getDropdown(Lookup.getLiability, "Liability")}
            hint="This road user was responsible for the crash by what percentage?"/>          

          <Form.Field
            width={1} label="Hit and Run"
            name={`vehicles[${props.index}].hit_and_run`}
            value={props.data.hit_and_run}
            control={Lookup.getDropdown(Lookup.getHitAndRun, "Hit and Run")}
            hint="Please select a hit and run situation."/>

          <Form.Field
            width={1} label="Vehicle Location (Carriageway)"
            name={`vehicles[${props.index}].carriageway_location`}
            value={props.data.carriageway_location}
            control={Lookup.getDropdown(Lookup.getCarriagewayLocation, "Vehicle Location (Carriageway)")}
            hint="Please indicate the vehicle location at the time of the accident (restricted lane/away from main carriageway)."/>

          {props.accident.situation_junction_type > 0 && <Form.Field
            width={1} label="Vehicle Location (Junction)"
            name={`vehicles[${props.index}].junction_location`}
            value={props.data.junction_location}
            control={Lookup.getDropdown(Lookup.getJunctionLocation, "Vehicle Location (Junction)")}
            hint="Please indicate the junction location of the vehicle."/>}

          <Form.Field
            width={1} label="Manoeuvres"
            name={`vehicles[${props.index}].manoeuvre`}
            value={props.data.manoeuvre}
            control={Lookup.getDropdown(Lookup.getManoeuvres, "Manoeuvre")}
            hint="Please select a vehicle manoeuvre."/>

          <Form.Field
            width={1} label="Skidding and Overturning"
            name={`vehicles[${props.index}].skid_overturn`}
            value={props.data.skid_overturn}
            control={Lookup.getDropdown(Lookup.getSkiddingOverturning, "Skidding and Overturning")}
            hint="Please select a skidding or overturning situation."/>

          <Form.Field
            width={1} label="Hit Object in Carriageway"
            name={`vehicles[${props.index}].hit_object_in_carriageway`}
            value={props.data.hit_object_in_carriageway}
            control={Lookup.getDropdown(Lookup.getHitObjectInCarriageway, "Hit Object in Carriageway")}
            hint="Please select an object hit in the carriageway, if any."/>

          <Form.Field
            width={1} label="Vehicle Leaving Carriageway"
            name={`vehicles[${props.index}].leaving_carriageway`}
            value={props.data.leaving_carriageway}
            control={Lookup.getDropdown(Lookup.getVehicleLeavingCarriageway, "Vehicle Leaving carriageway")}
            hint="Please select how the vehicle left the carriageway."/>            

          <Form.Field
            width={1} label="First Object Hit off Carriageway"
            name={`vehicles[${props.index}].hit_object_off_carriageway`}
            forceupdate
            value={props.data.hit_object_off_carriageway}
            control={Lookup.getDropdown(Lookup.getHitObjectOffCarriageway, "First Object Hit off Carriageway")}
            hint="Please select an object hit off the carriageway, if any."/>                        

          {props.data.hit_object_off_carriageway == 11 && 
            <Form.Field
              width={1} label="Other obstruction"
              name={`vehicles[${props.index}].other_obstruction`}
              value={props.data.other_obstruction}
              control={<Input fluid placeholder="Specify..."  type="text"/>}
              hint="Please specify the obstruction hit by the vehicle."/>}

          <Form.Field
            width={1} label="First Point of Impact"
            name={`vehicles[${props.index}].impact`}
            value={props.data.impact}
            control={Lookup.getDropdown(Lookup.getImpact, "First Point of Impact")}
            hint="Please select the first point of impact on the vehicle, if any."/>                          

          <Flex.Columns count={2}>
            <Form.Field
              width={1} label="Travel From"
              name={`vehicles[${props.index}].direction_from`}
              value={props.data.direction_from}
              control={<Rose/>}
              hint="Please select the direction the vehicle came from."/>                          
  
            <Form.Field
              width={1} label="Travel To"
              name={`vehicles[${props.index}].direction_to`}
              value={props.data.direction_to}
              control={<Rose/>}
              hint="Please select the direction the vehicle travelled to."/>                
          </Flex.Columns>
        
        </UI.Block>

      </div>

    </Flex.Columns>
  )
}

export { FormVehicle }
