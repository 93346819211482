import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, Icon, Loader, ToastService } from "@independent-software/typeui/controls"

import { useAuth } from '../../auth/useAuth';
import { IAccount } from '../../api/user/IAccount';
import { UserApi } from '../../api/user/UserApi';
import { AccountForm } from './AccountForm';
import { UI } from '../../components/UI/UI';

const Account = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [account, setAccount] = React.useState<IAccount>({ password: null, new_password: null});
  const [error, setError] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  const handleChange = (value: any) => {
    setAccount(value);
  }

  const handleValidate = (valid: boolean) => {
    setValid(valid);
  }

  const handleSave = () => {
    setSaving(true);
    setError(null);
    UserApi.change_password(account, auth.access_token)
    .then((res) => {
      ToastService.toast("Password changed.");
      navigate("/");
    })
    .catch(err => setError(err))
    .finally(() => setSaving(false));
  }

  return (
    <>
      <UI.PaddedScreen>
        <UI.Header>Account</UI.Header>
        {saving && <Loader></Loader>}
        <UI.Content>
          <AccountForm account={account} onChange={handleChange} onValidate={handleValidate}/>
        </UI.Content>
        <UI.Footer>
          <UI.FooterSection>
            <Button secondary onClick={() => navigate("/")}>Cancel</Button>
          </UI.FooterSection>
          <UI.FooterSection>
            <Button primary disabled={!valid} onClick={handleSave}><Icon name="save"/> Change password</Button>
          </UI.FooterSection>
        </UI.Footer>
      </UI.PaddedScreen>
      <Dialog.Xhr open={error != null} error={error} onClose={() => setError(null)} onRetry={handleSave}/>
    </>      
  )
}

export { Account }
