import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@independent-software/typeui/styles/Theme';

import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Button } from '@independent-software/typeui/controls/Button'
import { Icon } from '@independent-software/typeui/controls/Icon'

import { AuthApi } from '../../api/auth/AuthApi';
import { ResetForm } from './ResetForm';

// Adds a timeout to make sure that request button animation actually gets seen:
const BUTTON_TIMEOUT = 250; // ms

const Reset = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [credentials, setCredentials] = React.useState<{ password: string }>({ password: "" });
  const [loading, setLoading] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  const handleChange = (data: { password: string }, forceupdate: boolean) => {
    setCredentials(data);
  }  

  const handleValidate = (valid: boolean) => {
    setValid(valid);
  }  

  const handleSubmit = () => {
    if(loading) return;
    setLoading(true);
    setTimeout(() => {
      AuthApi.reset(params.token, credentials.password)
      .then(res => {
        setLoading(false);
        ToastService.toast("Reset successful.");
        navigate("/");
      })
      .catch(err => {
        setLoading(false);
        ToastService.toast("Reset failed.");
      });
    }, BUTTON_TIMEOUT);
    // Adds a 250ms timeout to make sure that request button
    // animation actually gets seen.    
  }    

  return (
    <div>
      <Heading>Password reset</Heading>
      <ResetForm disabled={loading} data={credentials} onChange={handleChange} onValidate={handleValidate} onSubmit={handleSubmit}/>
      <div style={{display: 'flex', 'justifyContent': 'space-between'}}>
        <Button primary disabled={!valid} onClick={handleSubmit}>
          {loading  && <Icon loading name="circle-notch"/>}
          {!loading && "Reset"}
          {loading  && "Resetting..."}
        </Button>
      </div>
    </div>
  )
}

const Heading = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #616A7C;
  margin-bottom: 8px;
`  

export { Reset }
