import * as React from 'react';

import { Input } from '@independent-software/typeui/controls/Input';
import { Form } from '@independent-software/typeui/controls/Form';
import { Checkbox, Flex } from '@independent-software/typeui/controls';

import { IProfile } from '../../api/profile/IProfile';
import { UI } from '../../components/UI/UI';

interface IProps {
  /** Initial form data. */
  data: IProfile;
  /** Called whenever form changes. */
  onChange: (data: IProfile, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

const ProfileForm = (props: IProps) => {
  const [data, setData] = React.useState<IProfile>(props.data);

  return (
    <Form
      data={data} dirty={props.dirty} onChange={props.onChange} onValidate={props.onValidate}>
      <div>
        <UI.Block title="Profile data">
          <Form.Field 
            required={{message: "Profile name is required."}}
            minLength={{length: 3, message: "Profile name must be at least 3 characters."}}
            maxLength={{length: 255, message: "Profile name may not be longer than 255 characters."}}
            width={1} label="Profile name"
            name="name" 
            value={data.name}
            control={(<Input type="text" placeholder="Name" fluid/>)}
            hint={(<>Please enter a profile name, e.g. <code>Data entry</code>.</>)}/>
        </UI.Block>

        <UI.Block title="Permissions">
          <Flex.Columns count={2}>
            <Form.Field
              label="View users"
              name="can_view_users"
              value={data.can_view_users}
              control={(<Checkbox type="toggle" label="View users"/>)}
              hint={(<>Can members of this profile view user records?</>)}/>

            <Form.Field
              label="Edit users"
              name="can_edit_users"
              value={data.can_edit_users}
              control={(<Checkbox type="toggle" label="Edit users"/>)}
              hint={(<>Can members of this profile edit and delete user records?</>)}/>          

            <Form.Field
              label="View profiles"
              name="can_view_profiles"
              value={data.can_view_profiles}
              control={(<Checkbox type="toggle" label="View profiles"/>)}
              hint={(<>Can members of this profile view profile records?</>)}/>                    

            <Form.Field
              label="Edit profiles"
              name="can_edit_profiles"
              value={data.can_edit_profiles}
              control={(<Checkbox type="toggle" label="Edit profiles"/>)}
              hint={(<>Can members of this profile edit and delete profile records?</>)}/>      

            <Form.Field
              label="View logs"
              name="can_view_logs"
              value={data.can_view_logs}
              control={(<Checkbox type="toggle" label="View logs"/>)}
              hint={(<>Can members of this profile view logs?</>)}/>                

            <Form.Field
              label="Edit accidents"
              name="can_edit_accidents"
              value={data.can_edit_accidents}
              control={(<Checkbox type="toggle" label="Edit accidents"/>)}
              hint={(<>Can members of this profile edit accidents?</>)}/>                 
          </Flex.Columns>
        </UI.Block>

      </div>
    </Form>
  )
}

export { ProfileForm }
