import * as React from 'react'
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
}

const LogoBase = (props: IProps) => {
  return (
    <svg className={props.className}>
      <use xlinkHref={`sprites.svg#small-logo`}></use>
    </svg>
  );
}

const Logo = styled(LogoBase)`
  height: 24px;
  fill: #fff;
  width: 48px;
  height: 48px;
`

export { Logo }
