import * as React from 'react';
import styled, { css } from 'styled-components';
import { TTableState } from '../../contexts/FilterContext';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  tableState: TTableState;
}

const TableHolderBase = (props: IProps) => 
  <div className={props.className}>
    {props.children}
  </div>

const TableHolder = styled(TableHolderBase)`
  position: absolute;
  left: 0;
  ${p => p.tableState == 'closed' && css`width: 0%;`}
  ${p => p.tableState == 'open' && css`width: 50%;`}
  ${p => p.tableState == 'full' && css`width: 100%;`}
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: width ease-in-out 150ms;
  z-index: 1000;
  overflow-x: hidden;
  box-shadow: 1px 0px 2px rgba(0,0,0,0.8);
  background: #fff;
`

export { TableHolder }
