import * as React from 'react';
import styled from 'styled-components';

import { Form } from '@independent-software/typeui/controls/Form';
import { Button, Icon, Label, Tabs, Message } from '@independent-software/typeui/controls';

import { IAccident } from '../../../api/accident/IAccident';
import { UI } from '../../../components/UI/UI';
import { FormLocation } from '../forms/FormLocation';
import { FormSituation } from '../forms/FormSituation';
import { FormVehicle } from '../forms/FormVehicle';
import { FormCasualty } from '../forms/FormCasualty';
import { FormFactors } from '../forms/FormFactors';
import { FormComment } from './FormComment';

interface IProps {
  /** Initial form data. */
  data: IAccident;
  /** Called whenever form changes. */
  onChange: (data: IAccident, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

const AccidentForm = (props: IProps) => {
  const [data, setData] = React.useState<IAccident>(props.data);

  const handleAddVehicle = () => {
    const newData = { ...data, vehicles: [...data.vehicles, {}]};
    setData(newData);
    props.onChange(newData, true);
  }

  const handleDeleteVehicle = (idx: number) => {
    data.vehicles.splice(idx, 1);
    const vehicles = data.vehicles;
    const newData = { ...data, vehicles };
    setData(newData);
    props.onChange(newData, true);
  }

  const handleAddCasualty = () => {
    const newData = { ...data, casualties: [...data.casualties, {}]};
    setData(newData);
    props.onChange(newData, true);
  }

  const handleDeleteCasualty = (idx: number) => {
    data.casualties.splice(idx, 1);
    const casualties = data.casualties;
    const newData = { ...data, casualties };
    setData(newData);
    props.onChange(newData, true);
  }

  const handleToggleFactor = (idx: number) => {
    const pos = data.factors.findIndex(f => f.value == idx);
    if(pos != -1) {
      data.factors.splice(pos, 1);
    } else {
      data.factors.push({ value: idx });
    }
    const newData = {...data, factors: data.factors };
    setData(newData);
    props.onChange(newData, true);
  }

  const handleToggleSpecial = (idx: number) => {
    const special = data.situation_special ?? "";
    let parts = special.split(" ").filter(p => p != "");
    if(parts.includes(idx.toString())) {
      parts = parts.filter(p => p != idx.toString());
    } else {
      parts.push(idx.toString());
    }
    const newData = { ...data, situation_special: parts.join(" ")}
    setData(newData);
    props.onChange(newData, true);
  }

  return (
    <Form data={data} dirty={props.dirty} onChange={props.onChange} onValidate={props.onValidate}>

      <Tabs underlined>
        <Tabs.Pane label="Location & Date">
          <Scroller>
            <FormLocation data={data}/>
          </Scroller>
        </Tabs.Pane>

        <Tabs.Pane label="Situation">
          <Scroller>
            <FormSituation data={data} onToggleSpecial={handleToggleSpecial}/>
          </Scroller>
        </Tabs.Pane>

        <Tabs.Pane label={<>Vehicles <Label size="small">{data.vehicles.length}</Label></>}>
          <Scroller>
            {data.vehicles.length == 0 && <>
              <Message type="info">
                No vehicles have been recorded for this accident.
              </Message>
            </>}            
            {data.vehicles.map((vehicle, idx) => 
              <UI.Block key={idx} secondary title={<SectionBar>
                  <span>Vehicle {idx+1}</span>
                  <Button negative size="small" onClick={() => handleDeleteVehicle(idx)}><Icon name="trash"/> Delete vehicle</Button>
                </SectionBar>}>
                <FormVehicle index={idx} data={vehicle} accident={data}/>
              </UI.Block>
            )}
            <ButtonHolder>
              <Button primary onClick={handleAddVehicle}><Icon name="plus"/> Add vehicle</Button>
            </ButtonHolder>
          </Scroller>
        </Tabs.Pane>

        <Tabs.Pane label={<>Casualties <Label size="small">{data.casualties.length}</Label></>}>
          <Scroller>
            {data.casualties.length == 0 && <>
              <Message type="info">
                No casualties have been recorded for this accident.
              </Message>
            </>}
            {data.casualties.map((casualty, idx) => 
              <UI.Block key={idx} secondary title={<SectionBar>
                  <span>Casualty {idx+1}</span>
                  <Button negative size="small" onClick={() => handleDeleteCasualty(idx)}><Icon name="trash"/> Delete casualty</Button>
                </SectionBar>}>
                <FormCasualty index={idx} data={casualty}/>
              </UI.Block>
            )}
            <ButtonHolder>
              <Button primary onClick={handleAddCasualty}><Icon name="plus"/> Add casualty</Button>
            </ButtonHolder>
          </Scroller>
        </Tabs.Pane>

        <Tabs.Pane label={<>Contributory factors <Label size="small">{data.factors.length}</Label></>}>
          <Scroller>
            <FormFactors factors={data.factors} onToggle={handleToggleFactor}/>
          </Scroller>
        </Tabs.Pane>    

        <Tabs.Pane label="Comments">
          <Scroller>
            <FormComment data={data}/>
          </Scroller>
        </Tabs.Pane>

      </Tabs>

    </Form>
  )
}

const Scroller = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 48px;
`

const SectionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonHolder = styled.div`
  display: flex;
  justify-content: end;
`

export { AccidentForm }
