import * as React from 'react';
import { AuthContext, IAuth } from './AuthContext';
import { ISigninResponse } from '../api/auth/SigninResponse';

interface IProps {
  children?: React.ReactNode;
}

const Auth = (props: IProps) => {

  const getInitialState = () => {
    // Try to load Auth from session storage.
    const authStr = sessionStorage.getItem('auth');
    const authData = authStr ? JSON.parse(authStr) : {};
    return {...data, ...authData};
  }

  const handleSignin = (response: ISigninResponse) => {
    const newData = { 
      ...data, 
      uuid: response.uuid,
      access_token: response.access_token,
      name: response.name,
      email: response.email,
      rights: response.rights
    }
    setData(newData);
    // Store auth in session:
    sessionStorage.setItem('auth', JSON.stringify(newData));
  }

  const handleSignout = () => {
    setData({
      ...data, 
      uuid: null,
      access_token: null,
      name: null,
      email: null,
      rights: []
    });
    // Remove auth from session:
    sessionStorage.removeItem('auth');
  }

  const [data, setData] = React.useState<IAuth>({
    uuid: null,
    access_token: null,
    name: null,
    email: null,
    rights: [],
    signin: handleSignin,
    signout: handleSignout,
  });  

  React.useEffect(() => {
    setData(getInitialState());
  }, [])

  return (
    <AuthContext.Provider value={data}>
      {props.children}
    </AuthContext.Provider>
  );  
}

export { Auth }
