import * as React from 'react'
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
}

const SeparatorBase = (props: IProps) => {
  return (
    <div className={props.className}></div>
  );
}

const Separator = styled(SeparatorBase)`
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: solid 1px #4C4A5D;
  box-shadow: 0px 0px 1px rgb(0,0,0,0.5);
  width: 48px;
`

export { Separator }
