import * as React from 'react';

import { Input } from '@independent-software/typeui/controls/Input';
import { Form } from '@independent-software/typeui/controls/Form';
import { Checkbox, Flex } from '@independent-software/typeui/controls';
import { UI } from '../../../components/UI/UI';
import { IAccident } from '../../../api/accident/IAccident';
import { Lookup } from '../Lookup';

interface IProps {
  /** Initial form props.data. */
  data: IAccident;  
  onToggleSpecial: (idx: number) => void;
}

const FormSituation = (props: IProps) => {

  return (
    <Flex.Columns count={2}>
            
      <div>

        <UI.Block title="Road">

          <Form.Field 
            // required={{ message: "Road type is required."}}
            width={1} label="Road Type"
            name="situation_road_type" 
            value={props.data.situation_road_type}
            forceupdate
            control={Lookup.getDropdown(Lookup.getRoadTypes, "Road Type")}
            hint="Please select the road type."/>

          {props.data.situation_road_type == 2 && <Form.Field 
            width={1} label="Road Lane"
            name="situation_road_lane" 
            value={props.data.situation_road_lane}
            control={Lookup.getDropdown(Lookup.getRoadLanes, "Road Lane")}
            hint="Please select the road lane."/>}           

          <Form.Field 
            isInt={{ message: "Speed limit must be a whole number."}}
            min={{ value: 0, message: "Speed limit must be at least 0."}}
            max={{ value: 99999, message: "Speed limit may not be greater than 99999."}}
            width={1} label="Speed Limit (Permanent)"
            name="situation_speed_limit" 
            value={props.data.situation_speed_limit}
            control={(<Input type="text" placeholder="Speed Limit" fluid/>)}
            hint="Please enter the local (permanent) speed limit."/>    

        </UI.Block>

        <UI.Block title="Junction">

          <Form.Field 
            width={1} label="Junction Type"
            name="situation_junction_type" 
            value={props.data.situation_junction_type}
            forceupdate
            control={Lookup.getDropdown(Lookup.getJunctionTypes, "Junction Type")}
            hint="Please select the junction detail."/>

          {props.data.situation_junction_type > 0 && <Form.Field 
            width={1} label="Junction Control"
            name="situation_junction_control" 
            value={props.data.situation_junction_control}
            control={Lookup.getDropdown(Lookup.getJunctionControl, "Junction Control")}
            hint={<>Please select the junction control.</>}/>}

        </UI.Block>

        <UI.Block title="Crossing">

          <Form.Field 
            width={1} label="Pedestrian Crossing - Human control"
            name="situation_pedestrian_human_control" 
            value={props.data.situation_pedestrian_human_control}
            control={Lookup.getDropdown(Lookup.getPedestrianHumanControl, "Human control")}
            hint="Please select the human control situation at any pedestrian crossing."/>

          <Form.Field 
            width={1} label="Pedestrian Crossing - Physical Facilities"
            name="situation_pedestrian_physical_facilities" 
            value={props.data.situation_pedestrian_physical_facilities}
            control={Lookup.getDropdown(Lookup.getPedestrianPhysicalFacilities, "Physical facilities")}
            hint="Please select the physical facilities at any pedestrian crossing."/>

        </UI.Block>

      </div>
      <div>

        <UI.Block title="Conditions">

          <Form.Field 
            width={1} label="Weather"
            name="situation_weather" 
            value={props.data.situation_weather}
            control={Lookup.getDropdown(Lookup.getWeather, "Weather")}
            hint="Please select the weather conditions."/>

          <Form.Field 
            width={1} label="Road Surface Condition"
            name="situation_road_surface" 
            value={props.data.situation_road_surface}
            control={Lookup.getDropdown(Lookup.getRoadSurface, "Road Surface Condition")}
            hint="Please select the road surface condition."/>          

          <Form.Field 
            width={1} label="Light Conditions"
            name="situation_light" 
            value={props.data.situation_light}
            control={Lookup.getDropdown(Lookup.getLight, "Light Conditions")}
            hint="Please select the light conditions."/>               

          <Form.Uncontrolled label="Special Conditions" hint="Please select any special conditions that apply.">
            <div style={{ display: 'flex', flexDirection:'column', gap: '4px'}}>
              {/* For each special condition, render a Checkbox. */}
              {Lookup.getSpecialConditions.map((condition, index) => 
                <Checkbox key={index} type="check" 
                  checked={!!(props.data.situation_special && props.data.situation_special.split(" ").includes(index.toString()))} 
                  label={condition}
                  onChange={() => props.onToggleSpecial(index)}
                />              
              )}
            </div>       
          </Form.Uncontrolled>                                                                         

        </UI.Block>
        
        <UI.Block title="Other">

          <Form.Field 
            // required={{ message: "Carriageway hazards is required."}}
            width={1} label="Carriageway Hazards"
            name="situation_carriageway_hazards" 
            value={props.data.situation_carriageway_hazards}
            control={Lookup.getDropdown(Lookup.getCarriagewayHazards, "Carriageway Hazards")}
            hint="Please select an applicable carriageway hazard."/>    

          <Form.Field 
            // required={{ message: "Officer presence is required."}}
            width={1} label="Officer Present"
            name="situation_officer_present" 
            value={props.data.situation_officer_present}
            control={Lookup.getDropdown(Lookup.getYesNo, "Officer Present")}
            hint="Did a police officer attend the scene and obtain the details for this report?"/>

        </UI.Block>

      </div>

    </Flex.Columns>
  )
}

export { FormSituation }
