import { App } from "../../App";
import { Api } from "../Api";
import { IProfile } from "./IProfile";
import { IProfileList } from "./IProfileList";

class ProfileApi {

  static list = async (order: string, dir: string, offset: number, count: number, q: string, access_token: string, signal?: AbortSignal): Promise<IProfileList> => {
    const url = new URL(App.config.apiURL + 'profiles');
    url.searchParams.append('order', order);
    url.searchParams.append('dir', dir);
    url.searchParams.append('offset', offset.toString());
    url.searchParams.append('count', count.toString());
    if(q) url.searchParams.append('q[]', `like-${q}`);
    return Api.Get<IProfileList>(url.toString(), access_token, signal);
  }

  static get = async (uuid: string, access_token: string, signal?: AbortSignal): Promise<IProfile> => {
    return Api.Get<IProfile>(App.config.apiURL + `profile/${uuid}`, access_token, signal);
  }

  static create = async (profile: IProfile, access_token: string, signal?: AbortSignal): Promise<IProfile> => {
    return Api.Post<IProfile>(App.config.apiURL + `profile`, profile, access_token, signal);
  }

  static update = async (profile: IProfile, access_token: string, signal?: AbortSignal): Promise<IProfile> => {
    return Api.Put<IProfile>(App.config.apiURL + `profile`, profile, access_token, signal);
  }

  static delete = async (uuid: string, access_token: string, signal?: AbortSignal) => {
    return Api.Delete(App.config.apiURL + `profile/${uuid}`, access_token, signal);
  }

}

export { ProfileApi }