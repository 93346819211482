import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';

import { useAuth } from '../../auth/useAuth';
import { AccidentForm } from './forms/AccidentForm';
import { IAccident } from '../../api/accident/IAccident';
import { UI } from '../../components/UI/UI';
import { AccidentApi } from '../../api/accident/AccidentApi';
import { useFilter } from '../../contexts/useFilter';
import { format } from 'date-fns';
import { IAccidentProxy } from '../../api/accident/IAccidentProxy';

type TStep = 'ready' | 'saving' | 'saveError';

const AddAccident = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const filter = useFilter();
  const currentAccident = React.useRef<IAccident>({ date: format(Date.now(), 'yyyy-MM-dd'),  vehicles: [], casualties: [], factors: [] });
  const [accident, setAccident] = React.useState<IAccident>(currentAccident.current);
  const [step, setStep] = React.useState<TStep>("ready");
  const [dirty, setDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState(null)

  const handleChange = (newAccident: IAccident, forceupdate: boolean) => {
    currentAccident.current = Object.assign(currentAccident.current, newAccident);
    if(forceupdate) { 
      setAccident({...newAccident});
    }    
  }

  const handleValidate = (valid: boolean) => {
    setIsValid(valid);
  }  

  const handleSubmit = () => {
    setAccident(currentAccident.current);
    setDirty(true);
    if(!isValid) return;
    setError(null);
    setStep('saving');

    AccidentApi.create(currentAccident.current, auth.access_token)
    .then((res) => {
      const proxy: IAccidentProxy = {
        uuid: res.uuid,
        datetime: res.date + ' ' + res.time,
        latitude: res.latitude,
        longitude: res.longitude,
        general_town: res.general_town,
        vehicles_count: res.vehicles.length,
        casualties_count: res.casualties.length    
      }
      ToastService.toast("Accident recorded successfully.");
      filter.addAccident(proxy);
      navigate(`/accident/${res.uuid}`);
      setStep('ready');
    })
    .catch(error => {
      setStep('saveError');
      setError(error);
    });
  }

  const handleCancelSave = () => {
    setStep('ready');
  }

  return (
    <UI.PaddedScreen>
      <UI.Header>Add Accident</UI.Header>
      {step == 'saving' && <Loader></Loader>}
      <UI.Content>
        <AccidentForm dirty={dirty} data={accident} onChange={handleChange} onValidate={handleValidate}/>
      </UI.Content>
      <Dialog.Xhr open={step == 'saveError'} error={error} onClose={handleCancelSave} onRetry={handleSubmit}/>
      <UI.Footer>
        <UI.FooterSection>
          <Button secondary onClick={() => navigate("/")}>Cancel</Button>
        </UI.FooterSection>
        <UI.FooterSection>
          <Button primary disabled={!isValid} onClick={handleSubmit}><Icon name="save"/> Save accident</Button>
        </UI.FooterSection>
      </UI.Footer>
    </UI.PaddedScreen>
  );
}

export { AddAccident }

