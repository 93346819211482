import * as React from 'react';

import { UI } from '../../components/UI/UI';
import { LogList } from './LogList';

const Logs = () => {
  return (
    <UI.PaddedScreen>
      <UI.Header>Activity Log</UI.Header>
      <LogList/>
    </UI.PaddedScreen>
  )
}

export { Logs }
