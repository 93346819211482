import * as React from 'react';
import { Flex, Table } from '@independent-software/typeui/controls';

import { UI } from '../../../components/UI/UI';
import { Answer } from '../controls/Answer';
import { IVehicle } from '../../../api/accident/IVehicle';
import { Lookup } from '../Lookup';
import { differenceInYears, format, parseISO } from 'date-fns';
import { IAccident } from '../../../api/accident/IAccident';

interface IProps {
  vehicle: IVehicle;
  accident: IAccident;
}

const TableVehicle = (props: IProps) => 
  <Flex.Columns count={2}>
    
    <div>
      <UI.Block title="Vehicle Information">
        <Table striped transparent>
          <tbody>
            <Answer label="Vehicle Registration" value={props.vehicle.registration}/>
            <Answer label="Right-Hand Drive?" value={props.vehicle.right_hand} src={Lookup.getRightHand}/>
            <Answer label="Type of Vehicle" value={props.vehicle.type} src={Lookup.getVehicleType}/>
            <Answer label="Towing or Atriculation" value={props.vehicle.towing_articulation} src={Lookup.getTowingArticulation}/>
          {/* Field is only shown for vehicle types with windows: */}
          {[0,1,2,3,4,5,6,14,15].includes(props.vehicle.type) && 
            <Answer label="Tinted windows" value={props.vehicle.tinted_windows} src={Lookup.getYesNo}/>}
          </tbody>
        </Table>      
      </UI.Block>

      <UI.Block title="Driver Information">
        <Table striped transparent>
          <tbody>
            <Answer label="Sex of Driver" value={props.vehicle.driver_sex} src={Lookup.getSex}/>
            <Answer label="Ethnicity of Driver" value={props.vehicle.driver_ethnicity} src={Lookup.getEthnicity}/>
            <Answer label="Guyanese National?" value={props.vehicle.driver_national} src={Lookup.getYesNo}/>
            {props.vehicle.driver_national == 1 &&
              <Answer label="Nationality of Driver" value={props.vehicle.driver_nationality} src={Lookup.getNationality}/>}
            {props.vehicle.driver_dob && 
              <Answer label="Driver Date of Birth" value={<>{format(parseISO(props.vehicle.driver_dob), "dd-MM-yyyy")} ({differenceInYears(Date.now(), parseISO(props.vehicle.driver_dob))}y)</>}/>}
            {!props.vehicle.driver_dob && <Answer label="Age of Driver" value={props.vehicle.driver_age}/>}
            <Answer label="Driver Name" value={props.vehicle.driver_name}/>
            <Answer label="Driver Address" value={props.vehicle.driver_address}/>
            <Answer label="Licence Status" value={props.vehicle.driver_licence} src={Lookup.getLicence}/>
            <Answer label="Breath Test" value={props.vehicle.driver_breath_test} src={Lookup.getBreathTest}/>
            <Answer label="Journey Purpose" value={props.vehicle.driver_purpose} src={Lookup.getJourneyPurpose}/>
          </tbody>
        </Table>      
      </UI.Block>      
     
    </div>

    <div>
      <UI.Block title="Involvement">
        <Table striped transparent>
          <tbody>
            <Answer label="Liability" value={props.vehicle.driver_liability} src={Lookup.getLiability}/>
            <Answer label="Hit and Run" value={props.vehicle.hit_and_run} src={Lookup.getHitAndRun}/>
            <Answer label="Vehicle Location (Carriageway)" value={props.vehicle.carriageway_location} src={Lookup.getCarriagewayLocation}/>
            {props.accident.situation_junction_type > 0 && <Answer label="Vehicle Location (Junction)" value={props.vehicle.junction_location} src={Lookup.getJunctionLocation}/>}
            <Answer label="Manoeuvres" value={props.vehicle.manoeuvre} src={Lookup.getManoeuvres}/>
            <Answer label="Skidding and Overturning" value={props.vehicle.skid_overturn} src={Lookup.getSkiddingOverturning}/>
            <Answer label="Hit Object in Carriageway" value={props.vehicle.hit_object_in_carriageway} src={Lookup.getHitObjectInCarriageway}/>
            <Answer label="Vehicle Leaving Carriageway" value={props.vehicle.leaving_carriageway} src={Lookup.getVehicleLeavingCarriageway}/>
            <Answer label="First Object Hit off Carriageway" value={props.vehicle.hit_object_off_carriageway} src={Lookup.getHitObjectOffCarriageway}/>
            {props.vehicle.hit_object_off_carriageway == 11 && <Answer label="Other obstruction" value={props.vehicle.other_obstruction}/>}
            <Answer label="First Point of Impact" value={props.vehicle.impact} src={Lookup.getImpact}/>
            <Answer label="Travel From" rose value={props.vehicle.direction_from}/>
            <Answer label="Travel To" rose value={props.vehicle.direction_to}/>
          </tbody>
        </Table>      
      </UI.Block>         
    </div>

  </Flex.Columns>

export { TableVehicle }
